export default {
  route: {
    dashboard: 'Dashboard',
    orders: 'Orders',
    brands: 'Brands',
    invoices: 'Invoices',
    prepaidAccounts: 'Prepaid accounts',
    prepaidAccountView: 'Prepaid account',
    brandView: 'Brand view',
    profile: 'Profile',
    purchase: 'Order taking',
    fileMapper: 'File Mapper',
    stocks: 'Stocks',
    volumeByBuyers: 'Volume per buyer',
    ratesManagement: 'Offers management',
    notifications: 'Notifications',
    MultiChoiceCards: 'Multi-choice cards',
    operation: 'Operations',
    purchaseInfos: 'Purchase infos',
    buyersManagement: 'Buyers management',
    buyersManagementView: 'Buyer management',
    customers: 'Customers',
    cards: 'eBons traceability'
  },
  navbar: {
    logOut: 'Disconnect from market',
    logOutWalleo: 'Disconnect from market and walleo account',
    dashboard: 'Dashboard',
    profile: 'Profile'
  },
  login: {
    title: 'Login Form',
    logIn: 'Log in',
    username: 'Email',
    password: 'Password',
    passwordConfirmation: 'Password Confirmation',
    any: 'any',
    emailError: 'Please enter the correct email address',
    passwordLengthError: 'The password can not be less than 6 digits',
    wrongCredentialsError: 'Wrong email or password',
    forgotPassword: 'Forgot password'
  },
  profile: {
    cardTitle: 'My profile',
    tabs: {
      account: {
        title: 'Account',
        personalInfo: 'Personal informations',
        firstname: 'First name',
        lastname: 'Last name',
        email: 'Email',
        password: 'Password',
        changePassword: 'Change password',
      }
    }
  },
  dashboard: {
    selectPeriod: "Select a period",
    periods: {
      lastMonth: 'Last month',
      today: "Today",
      week: 'This week',
      month: 'This month',
      year: 'This year'
    },
    stats: {
      stat1: 'API orders / Bulk orders',
      stat2: 'Amount in facial value',
      stat3: 'Commission',
    },
    volumesBarChart: {
      title: 'Volumes',
      options: {
        buy: "of purchase",
        sell: 'of sale'
      },
      volumesBy: {
        total: 'Total per',
        giftCards: 'brand',
        buyers: 'buyer'
      }
    },
    leaderboardChart: {
      title: 'Top',
      options: {
        giftCards: 'brands',
        buyers: 'buyers'
      },
    },
    table: {
      ref: 'Reference',
      amount: 'Amount',
      faceValue: 'Facial value',
      commissionRate: 'Commission rate excl.',
      commissionHT: 'Commission excl.',
      commissionTTC: 'Commission incl. VAT',
      date: 'Date',
    },
    hello: 'Hi',
    connectedAs: 'Connected as',
    activityBlock: {
      title: 'Activity',
      subtitle: 'See each column for more details',
      turnover: 'turnover',
      ordersNotProcessed: 'orders to be processed',
      ordersNotDelivered: 'orders to be delivered',
      ordersUnpaid: 'unpaid orders'
    },
    ordersBlock: {
      title: 'Orders',
      subtitle: 'Orders requiring your intervention',
      noPaymentRecorded: 'No payment recorded',
      orderNotProceed: 'Order has not been processed'
    },
    prepaidAccountBlock: {
      prepaidAccount: 'available balance',
      creditWaiting: 'pending credit note'
    }
  },
  orders: {
    header: {
      title: 'Order tracking',
      subtitle: 'Find your orders on this interface',
      btnSwitchToBuyer: 'View my buyer orders',
      btnSwitchToSeller: 'View my seller orders'
    },
    splittedTable: {
      btnSplit: 'Split mode',
      table1: 'Buyer orders',
      table2: 'Seller orders',
    },
    buyer: {
      filters: {
        filterBtn: 'Refine orders by',
        title: 'Refine',
        subtitle: 'Get better results using our smart filters',
        orderNumber: 'Order number',
        refCustomer: 'External transaction reference',
        timeInterval: 'Time range',
        startDate: 'Start date',
        to: 'to',
        endDate: 'End date',
        status: 'Order status',
        paymentStatus: 'Payment status',
        deliveryStatus: 'Delivery status',
        gitfCard: 'Brands',
        buyers: 'Buyers',
        buyersInfo: 'Applicable only on buyers order view',
        applyFilters: 'Apply and see'
      },
      codes: {
        download: 'Download raw codes',
        multipleFiles: 'files of codes to be downloaded'
      },
      card: {
        ref: 'Ref.',
        buyer: 'Buyer',
        faceValue: 'Face value',
        activeFrom: 'Active from',
        orderPaid: 'Paid',
        orderPaidOn: 'Paid on',
        status: {
          text: 'Status',
          refunded: 'Refunded',
          yes: 'Yes',
          partial: 'Partial',
          no: 'No',
          inProgress: 'In progress'
        },
        paymentDeadline: 'Payment due date',
        btnSeeMoreDetails: 'See more details',
        exceeding: 'exceeding of',
        orderDelivered: 'Delivered',
        orderDeliveredOn: 'Delivered on',
        detailOrder: 'Please detail your order',
        confirmOrder: 'Confirm order ?',
        proceed: 'Go',
        notNow: 'Not now',
        processOrder: 'Process my order',
        waitingDetails: 'Waiting for detail',
        btnPaymentWithBalance: 'Pay',
        paymentWithBalanceOptions: {
          opt1: 'Confirm',
          opt2: 'Cancel'
        },
        orderInfo: {
          phrasing1: 'To facilitate the identification of your future payment of',
          phrasing2: ', thank you for using',
          phrasing3: 'as bank transfer reference.'
        },
        menu: {
          details: 'Details',
          edit: 'Edit',
          delete: 'Delete',
          resume: 'Resume',
          suspend: 'Suspend'
        },
        deleteButtonConfirm: {
          title: 'Are you sure you want to delete this order ?',
          opt1: 'Confirm',
          opt2: 'Cancel'
        },
      }
    },
    seller: {
      header: {
        title: 'Orders monitoring',
        subtitle: 'Keep an eye on your deliveries'
      },
      filters: {
        filterBtn: 'Refine orders by',
        title: 'Refine',
        subtitle: 'Get better results using our smart filters',
        orderNumber: 'Order number',
        timeInterval: 'Time range',
        startDate: 'Start date',
        to: 'to',
        endDate: 'End date',
        orderStatus: 'Delivery status',
        applyFilters: 'Apply and see'
      },
      status: {
        complete: 'Complete delivery',
        partial: 'Partial delivery',
        waiting: 'Pending delivery',
        errors: {
          title: 'Delivery failure',
          details: 'No errors found | {count} Error found | {count} Errors found'
        }
      },
      card: {
        faceValue: 'Face value',
        progressBarTooltip: 'You have delivered a total of {amount} code(s) out of {total} code(s)',
        cardDistributions: 'Delivery detail',
        cardDistributionsDetails: 'card(s) of an amount equal to',
        cardDistributionsEmpty: 'Not specified',
        deliverCodes: 'Deliver the codes'
      },
      codesDialog: {
        title: 'Upload codes file',
        subtitle: 'Order #',
        step1: {
          note: 'Get sure your codes file does not contain any errors and that your File Mapper is correctly set up. The configuration of expiry date is at next step.'
        },
        step2: {
          codeActivationDate: 'Codes activation date',
          placeholderActivationDate: 'Indicate date and time of codes activation',
          note: 'Once activation date is filled, your file will be ready to be processed.',
          warning: 'Warning, after clicking on "Confirm delivery" codes are fully delivered',
          cancelBtn: 'Cancel',
          confirmBtn: 'Confirm delivery',
          processingBtn: 'Processing...'
        },
        step3: {
          deliveryErrors: {
            title: 'Delivery failed',
            subtitle: 'File uploaded contains errors. Check that the File Mapper used is correct. Find errors below.',
            backToOrderBtn: 'Back to orders',
            newDeliveryBtn: 'New delivery'
          },
          deliverySuccess: {
            title: 'The order #{number} has been delivered',
            codeActivationDate: 'Codes will be activated on:',
            backToOrderBtn: 'Back to orders'
          }
        },
        deliveryCodesDetails: {
          title: 'View order detail'
        },
        manageFileMapper: {
          title: 'Manage my File Mappers',
          byDefaultBtn: 'Set by default',
          notification: {
            success: {
              title: 'File Mapper updated',
              subtitle: 'Default File Mapper has been updated'
            },
            error: {
              title: 'File Mapper error',
              subtitle: 'Impossible to change default File Mapper'
            }
          }
        },
        handleDeliveryErrors: {
          title: 'A total of {amount} errors have been found upon delivery',
          subtitle: '- Please correct errors before making new delivery',
          moreDetailsBtn: '- View more details',
          errorLine: 'Error line'
        }
      },
      rules: {
        activationDateRequired: 'Activation date required',
        codeFileRequired: 'Code file required'
      }
    },
    wholesaler: {
      btnWholesalerOrders: 'Seller orders',
      btnSeeBuyersOrders: 'Buyer orders',
      btnLaunchDelivery: 'Launch delivery',
      seller: 'Seller',
    },
    notification: {
      delete: {
        title: 'Success',
        message: 'Delete Successfully'
      },
      resumeSuccess: {
        title: 'Success',
        message: 'The order has been put into processing'
      },
      resumeError: {
        title: 'Error',
        message: 'The order could not be processed'
      },
      suspendSuccess: {
        title: 'Success',
        message: 'The order has been suspended'
      },
      suspendError: {
        title: 'Error',
        message: 'The order could not be suspended'
      },
      deliverySuccess: {
        title: 'Delivery order',
        message: 'The delivery of the order is launched'
      },
      deliveryError: {
        title: 'Unable to deliver the order',
        message: 'The delivery could not be started'
      },
      downloadError: {
        title: 'Download failed',
        message: 'An error occurred while downloading'
      }
    },
    noMore: 'You have come to the end',
    noResults: 'No result',
    btnMore: 'Load more orders'
  },
  invoices: {
    header: {
      title: 'My invoices',
      subtitle: 'Find your invoices issued for each order',
      type: {
        seeInvoicesOfBtn: 'View invoices',
        buyers: 'buyers',
        sellers: 'sellers'
      },
      filters: {
        filterBtn: 'Filter my invoices',
        title: 'Filters',
        subtitle: 'Get better results by using our smart filters',
        paymentMode: 'Payment mode',
        invoicePer: 'Invoice per',
        invoicePerOrder: 'Per order',
        invoicePerMonth: 'Monthly',
        paymentModePrepaid: 'Prepaid',
        paymentModeDiffered: 'Delayed',
        invoiceNumber: 'Invoice number',
        timeInterval: 'Time range',
        startDate: 'Start date',
        to: 'to',
        endDate: 'End date',
        paymentStatus: 'Payment status',
        orderNumber: 'Order number',
        sellers: 'Sellers',
        buyers: 'Buyers',
        applyFilters: 'Apply and see'
      },
      extractInvoicesBtn: 'Extract invoices'
    },
    table: {
      col2: 'Date',
      col3: 'Invoice No.',
      col4: 'Mode',
      col5: 'Amount',
      col6: 'Status',
      seeMoreBtn: 'See more invoices'
    },
    asidePanel: {
      title: 'Invoice details',
      closeBtn: 'Close',
      multiBrands: 'Multi-brands',
      overpaymentOf: 'Overpayment of',
      underpaidOf: 'Underpayment of',
      type: 'Invoice type',
      standard: 'Standard',
      commission: 'Commission',
      issuedOn: 'Issued on',
      dueDate: 'Due date',
      payment: 'Payment',
      amountToBePaid: 'Total amount',
      balance: 'Balance due',
      number: 'N°',
      commentComponent: {
        comment: 'Comment',
        placeholder: 'Type your comment here...',
        notSpecified: 'No comment',
        btnAdd: 'Add a comment',
        btnEdit: 'Edit',
        btnSave: 'Save',
        btnCancel: 'Cancel',
        notification: {
          success: {
            title: 'Success',
            subtitle: 'Comment updated'
          },
          error: {
            title: 'Error',
            subtitle: 'An error occured'
          }
        }
      },
      notSpecified: 'Not specified',
      seeAndDownloadBtn: 'See and download my invoice',
      sendInvoiceBtn: 'Send my invoice',
      modifyInvoiceBtn: 'Edit my invoice',
      popoverValue: {
        number: {
          title: 'Edit invoice number',
          placeholder: 'Enter the invoice number',
        },
        customNumber: {
          title: 'Add a reference',
          titleEdit: 'Edit reference',
          placeholder: 'Enter the reference',
          btn: 'Add a reference',
        },
        error: {
          number: 'Invoice number is required',
          customNumber: 'Reference is required',
        }
      },
      paymentStatusResultSubtitles: {
        paid: 'No action required',
        unpaid: 'Please proceed to payment',
        late: 'You have a late payment'
      },
      relatedOrders: {
        title: 'Related Orders',
        ref: 'Ref.',
        notSpecified: 'No documented',
        seeMoreBtn: 'See more orders',
        inputOrderNumber: 'Order number'
      },
      relatedBankTransfer: {
        title: 'Related bank transfer(s)',
        total: 'Total transfer',
        empty: 'No bank transfer issued'
      },
      seePaymentProof: 'Check proof of payment'
    },
    uploadInvoiceDialog: {
      title: 'Download your invoice',
      invoiceNumber: 'Invoice number',
      invoice: 'Invoice',
      mandatoryFieldPDF: '(PDF only)',
      cancelBtn: 'Cancel',
      uploadInvoiceBtn: 'Download my invoice',
      numberIsRequired: 'Invoice number required',
      fileIsRequired: 'PDF file required',
      notification: {
        success: {
          title: 'Invoice downloaded',
          message: 'The invoice has been successfully downloaded'
        },
        error: {
          title: 'An error occured',
          message: 'The invoice could not be downloaded'
        }
      }
    }
  },
  extractInvoicesDialog: {
    title: 'Extract invoices',
    inputMonths: 'Dates of invoices to extract',
    to: 'to',
    startDate: 'Start date',
    endDate: 'End date',
    cancelBtn: 'Abort',
    extractBtn: 'Download the extraction in csv',
    notification: {
      error: {
        title: 'Error',
      }
    }
  },
  prepaidAccount: {
    header: {
      title: 'Overview of prepaid accounts',
      subtitle: 'Find your customers prepaid accounts on this page',
      title2: 'Prepaid account',
      subtitle2: 'Find the movements of your customers prepaid account on this page',
      stats: {
        stats1: 'available balance'
      },
      filters: {
        filterBtn: 'More filters',
        title: 'Filters',
        subtitle: 'Get better results using our smart filters',
        orderNumber: 'Order number',
        timeInterval: 'Time range',
        startDate: 'Start date',
        to: 'to',
        endDate: 'End date',
        giftCard: 'Brands',
        seller: 'Seller',
        reason: 'Reason',
        extraction: {
          title: 'Monthly reports',
          subtitle: 'Download your reports in csv format',
          btn: 'Download',
          notification: {
            error: {
              title: 'Error while extracting',
              message: 'Enter a period'
            }
          }
        },
        applyFilters: 'Apply and see',
        setGlobalViewBtn: 'Overview',
        setSellersViewBtn: 'View by seller',
      },
      alert: {
        title: 'balance alert',
        dialog: {
          title: 'Add available balance alert',
          subtitle: 'An email will be sent to you once the amount entered has been exceeded',
          rules: {
            required: 'Amount is required'
          }
        },
        notification: {
          success: {
            title: 'Succès',
            subtitle: 'Alerte de solde disponible ajoutée avec succès'
          },
          error: {
            title: 'Erreur',
            subtitle: 'L\'alerte de solde n\'a pas pu être ajouté'
          },
        }
      }
    },
    extractDialog: {
      title: 'Monthly reports',
      alert: {
        title: 'Information',
        subtitle: 'Filters are not applied when downloading the report'
      },
      inputMonths: 'Month of report to download',
      currentMonth: 'Current month',
      cancelBtn: 'Cancel',
      extractBtn: 'Download the report in csv',
    },
    table: {
      ref: 'Ref.',
      buyer: 'Buyer',
      requestId: 'Request ID',
      giftCard: 'Brand',
      bankTransfer: 'Bank transfer',
      amount: 'Amount',
      faceValue: 'Facial value',
      commissionRate: 'Commission rate excl.',
      commissionHT: 'Commission excl.',
      commissionTTC: 'Commission incl. VAT',
      date: 'Date',
      btnMore: 'See more moves',
      btnMoreBalance: 'See more balance',
      paginationOn: 'on',
      noResult: 'No result'
    },
    processing: 'Processing'
  },
  stock: {
    header: {
      title: 'My stock of e-gift card',
      subtitle: 'Find the stocks of each of your brands',
      stats: {
        stats1: 'order(s) to fulfill'
      },
      filters: {
        filterBtn: 'Filter/Sort the stock',
        title: 'Filters',
        subtitle: 'Get better results using our smart filters',
        giftCard: 'Brands',
        sortStock: 'Sort the stock',
        sortStockSubtitle: 'Sort your stock to prevent stock-outs',
        sortBy: 'Sorted by',
        options: {
          name: 'Name of the retail brand',
          delivery: 'Priority pruchase'
        },
        applyFilters: 'Apply and see'
      }
    },
    card: {
      faceValueStock: 'Stock in face value',
      modeTooltip: 'Supply method',
      orderToFulfill: 'Order(s) to fulfill',
      amountDetails: 'Detail of the amount',
      equalAmount: '{quantity} card(s) for an amount equal to {amount} | {quantity} card(s) for an amount equal to {amount} | {quantity} card(s) for an amount equal to {amount}',
      subtotal: 'Subtotal',
      createOrderBtn: 'Place an order',
      editOrderBtn: 'Edit the order',
      faceValue: 'Face value',
      toOrder: 'To order',
      availableStock: 'Available stock',
      available: 'Available',
      sharedStock: 'Shared stock',
      shared: 'Shared',
      reservedStock: 'Reserved stock',
      reserved: 'Reserved'
    },
    noMore: 'You have come to the end',
    noResults: 'No result',
    btnMore: 'Load more brands',
    notification: {
      success: {
        title: 'Order created',
        message: '#{number} has been placed in your orders'
      }
    }
  },
  volumeByBuyers: {
    header: {
      title: 'Purchase volume',
      subtitle: 'View your purchase volume',
      filters: {
        filterBtn: 'Filter buyers',
        title: 'Filters',
        subtitle: 'Get better results using our smart filters',
        findBuyer: 'Find a buyer',
        selectYear: 'Select a year',
        applyFilters: 'Apply and see'
      }
    },
    table: {
      col1: 'Buyer',
      col2: 'Applied rate',
      col3: 'Total volume',
      col4: 'Volume', // Ex: Volume (2022)
      col5: 'History (overview)',
      empty: 'No buyer'
    }
  },
  fileMapper: {
    title: 'Create a new File Mapper',
    uploadBlock: {
      title: 'Drag and drop your file',
      or: 'Or',
      title2: 'click hear to open file explorer',
      title3: '(Automatic detection of your file\'s headers)',
      errorMultipleFiles: 'Only support uploading one file!',
      errorUnreadbleFile: 'File not supported'
    },
    manualBtn: 'Add manually ',
    subtitleManual: 'Add manually the name of columns headers while assigning them to corresponding variables.',
    table: {
      col1: 'Generated on',
      col2: 'Default',
      col3: 'Cents'
    },
    fileMapperDialog: {
      new: 'File Mapper creation',
      edit: 'Update File Mapper',
      info: {
        title: 'Information',
        content: 'Code and Amount variables are mandatory to generate the File Mapper. Other available variables are optional.'
      },
      requiered: 'Required',
      selectHeader: 'Select an header',
      headerName: 'Header name',
      columnNumber: 'Column number',
      optional: 'Opt.', // 3 lettres MAX
      code: 'Code',
      amount: 'Amount',
      pinCode: 'PIN Code',
      expiration: 'Expiry',
      configuration: {
        fileFormat: 'File format',
        fileFormatLabel: 'Does your file has headers ?',
        additionalSetups: 'Additional settings',
        separator: 'Column separating character',
        separatorLabel: 'Indicate the separating character of each column of your file',
        byDefault: 'By default:',
        comma: 'comma',
        dateFormat: 'Date format',
        dateFormatLabel: 'Fill in the expiry date format',
        year: 'Year',
        month: 'Month',
        day: 'Day',
        exemple: 'Example',
        switchByDefault: 'Default File Mapper',
        switchAmountInCentimes: 'Amount expressed in cents'
      },
      cancelBtn: 'Cancel',
      editFileMapper: 'Update',
      createFileMapper: 'Create a File Mapper',
      errors: {
        codeRequiered: 'Code variable must be assigned',
        amountRequiered: 'Amount variable must be assigned'
      },
      notification: {
        success: {
          title: 'File Mapper created',
          subtitle: 'File Mapper successfully created'
        },
        update: {
          title: 'File Mapper updated',
          subtitle: 'File Mapper successfully updated'
        },
        error: {
          title: '',
          subtitle: ''
        }
      }
    }
  },
  brands: {
    header: {
      title: 'Brands',
      subtitle: 'Find information about your brands',
      productID: 'Product ID',
      filters: {
        giftCard: 'Find a brand'
      },
    },
    card: {
      copyID: 'Copy ID',
      copyProductID: 'Copier product ID',
      details: 'Details'
    },
    table: {
      noMore: 'You have come to the end',
      noResults: 'No result',
      btnMore: 'Load more brands',
      paginationOn: 'on'
    },
    view: {
      generalInformations: 'General informations',
      description: 'Description',
      descriptionOnWallet: 'This description will be displayed on the wallet brand information sheet',
      notSpecified: 'Not specified',
      baseline: 'Baseline / Brand signature',
      useForPDF: 'Use for PDF generation',
      use: 'Use',
      howToUseEbon: 'How to use an e-gift card',
      useEbonOn: 'Use e-gift card',
      online: '(Online)',
      offline: '(Offline)',
      termsOfUse: 'Terms of use',
      characteristic: 'Characteristic',
      cumulativeOnline: {
        title: 'Cumulative online',
        opt1: 'Yes',
        opt2: 'until',
        opt3: 'Unlimited',
        opt4: 'No'
      },
      cumulativeOffline: {
        title: 'Cumulative in store',
        opt1: 'Yes',
        opt2: 'until',
        opt3: 'Unlimited',
        opt4: 'No'
      },
      usable: {
        title: 'Use',
        opt1: 'Online / Store',
        opt2: 'Online only',
        opt3: 'Only in store'
      },
      splittable: {
        title: 'Splittable',
        opt1: 'Yes',
        opt2: 'No'
      },
      validity: {
        title: 'Validity',
        opt1: 'Unlimited'
      },
      pinCode: {
        title: 'Code PIN',
        opt1: 'Yes',
        opt2: 'No'
      },
      pinCodeConcatenation: {
        title: 'PIN code concatenation',
        opt1: 'Yes',
        opt2: 'No'
      },
      api: {
        title: 'API',
        opt1: 'Yes',
        opt2: 'No (Stock mode)'
      },
      barcodeSymbology: 'Barcode type',
      others: 'Others',
      onlineUrl: 'Online store URL',
      linkToAccess: 'Link to access the brand\'s online store',
      partnerStore: 'Partner shops',
      partnerStoreList: 'List of partner shops for the use of e-gift cards',
      sku: {
        title: 'Related Sku(s)',
        table: {
          col1: 'Value Type',
          col2: 'Value',
          col3: 'Free value (minimum)',
          col4: 'Free value (maximum)'
        },
        options: {
          yes: 'Free value',
          no: 'Fixed'
        }
      },
      images: {
        title: 'Image bank',
        squareLogo: 'Square logo',
        squareLogoDetails: 'Original size : 250x250px',
        bannerImage: 'Banner logo',
        bannerImageDetails: 'Original size : 550x250px',
        cardImage: 'Card image',
        cardImageDetails: `No default size (16/9)`,
        downloadBtn: 'Download'
      },
      period: {
        year: '{year} year | {year} years | {year} years',
        month: '{month} month | {month} months | {month} months',
        separator: 'and'
      }
    }
  },
  purchaseInfo: {
    header: {
      title: 'Management of commission rates',
      subtitle: 'Manage vendor and buyer commission rates'
    },
    stats: {
      title: 'Brands in management'
    },
    wrapper: {
      giftCardsList: {
        title: 'Brands',
        subtitle: 'No active brand | One active Brand | {total} active brands',
        filters: {
          giftCards: 'Search a brand',
          sellers: 'Search a supplier'
        }
      },
      title: `Brand rate management`,
      subtitle: 'No supplier found | One supplier found | {total} suppliers found',
      sellersList: {
        btnAddSeller: 'Add a seller',
        titleBtnOpen: `See more information`,
        titleBtnClose: `See less information`,
        lastUpdate: 'Last modification',
        never: 'None',
        informations: {
          title: 'General supplier information',
          col1: 'Commission rate',
          col2: 'Payment of VAT',
          col3: 'Commission payment method',
          col4: 'VAT payment mode',
          col5: 'Method of payment to the supplier',
          col6: 'API',
          col7: 'Payment mode'
        },
        characteristicDeferred: {
          title: 'Deferred payment’s characteristics',
          col1: 'Deferred limit',
          col2: 'Deferred used',
          col3: 'Deferred time',
          col4: 'Deferred deadline',
        },
        buyersTable: {
          title: 'Buyers informations',
          btnAdd: 'Add a buyer',
          col1: 'Buyer',
          col2: 'Commission rate',
          col3: 'Margin',
          col4: 'VAT',
          col5: 'Commission compensation mode',
          col6: 'Payment mode',
        }
      }
    },
    rateChangeSchedule: {
      popover: {
        title: 'Rate planning',
        inputBuyer: 'Choose a buyer',
        inputAmount: 'Enter a rate',
        inputTime: 'Choose a day and time',
        btnSave: 'Save',
        btnAdd: 'Plan a rate',
        rules: {
          buyer: 'Buyer is required',
          amount: 'Amount is required',
          time: 'Date and time are required',
        },
        notification: {
          success: {
            title: 'Planned rate',
            subtitle: 'The rate was planned successfully'
          },
          update: {
            title: 'Updated',
            subtitle: 'Planned rate was successfully changed'
          },
          error: {
            title: 'Error',
            subtitle: 'Rate could not be scheduled'
          }
        }
      },
      card: {
        title: 'Planned rate',
        on: 'on',
        notification: {
          success: {
            title: 'Success',
            subtitle: 'The planned rate has been deleted'
          },
          error: {
            title: 'Error',
            subtitle: 'The planned rate could not be deleted'
          }
        }
      }
    }
  },
  buyersManagement: {
    header: {
      title: 'Buyers management',
      subtitle: 'Manage buyers’s deposit account',
      btnAdd: 'Add a buyer'
    },
    stats: {
      title: 'Managed buyers'
    },
    card: {
      remainingBalance: 'Available balance',
      btnTopup: 'Top-up',
      btnEditDetails: 'Éditer / Détails'
    },
    table: {
      noMore: 'You have come to the end',
      noResults: 'No result',
      btnMore: 'Load more buyers'
    }
  },
  customers: {
    header: {
      title: 'Customers management',
      subtitle: 'Find the personal information of your customers',
      btnAdd: 'Add a customer',
      btnDomains: 'List of accepted domains',
      btnAddDomain: 'Add domain',
      filters: {
        sortByDate: 'Sort by creation date',
        sortByDateOptions: {
          desc: 'Clients the most recent',
          asc: 'Clients the oldest'
        },
        sortByUpdate: 'Sort by last activity',
        sortByUpdateOptions: {
          desc: 'Last activities',
          asc: 'Oldest activities'
        },
        sortByName: 'Sort by alphabetical order',
        sortByNameOptions: {
          asc: 'Last name (A-Z)',
          desc: 'Last name (Z-A)'
        },
        moreFiltersBtn: 'More filters',
        firstname: 'First name',
        lastname: 'Last name',
        email: 'Email',
        businessEmail: 'Business email',
        confirmed: 'Email confirmed (Login)',
        confirmedOptions: {
          notConfirmed: 'Not confirmed',
          confirmed: 'Confirmed'
        },
        approval: 'Approval',
        approvalOptions: {
          waiting: 'Waiting for login email confirmation',
          verification_failed: 'Approval failed',
          verified: 'Approved'
        },
        registrationFailureReason: 'Registration failure reason',
        registrationFailureReasonOptions: {
          no_reason: 'No reason',
          bad_email: 'Bad email',
          domain_unknown: 'Domain unknown',
          catch_all_domain: 'Catch all domain',
          unknown_email: 'Unknown email',
          risky_email: 'Risky email',
          mailgun_failed: 'Mailgun failed'
        },
        dateRange: 'Creation date',
        dateRangePlaceholder: {
          start: 'Start date',
          end: 'End date',
          separator: 'to'
        },
        program: 'Program'
      }
    },
    stats: {
      title: 'Total of customers'
    },
    card: {
      phone: 'Phone',
      situation: 'Situation',
      retired: 'Retired',
      employee: 'Employee',
      program: 'Program',
      approved: 'Approved',
      yes: 'Yes',
      auto: 'Auto',
      manual: 'Manual',
      waiting: 'Waiting for email confirmation',
      no: 'No',
      notSpecified: 'Unspecified',
      confirmedShort: 'Email confirmed',
      confirmedLong: 'Email confirmed (Login)',
      confirmedOptions: {
        notConfirmed: 'No',
        confirmed: 'Yes at'
      },
      btnEdit: 'Edit',
      btnVerify: 'Approve',
      btnDelete: 'Delete',
      popConfirmDelete: {
        title: 'Are you sure you want to delete this customer ?',
        confirmBtn: 'Confirm',
        cancelBtn: 'Cancel',
      },
      btnInvalidate: 'Unapprove',
      popoverFailure: {
        no_reason: 'No reason',
        domain_unknown: 'The domain is not referenced',
        bad_email: 'The business email does not exist for Avantages IEG',
        catch_all_domain: 'The domain accepts all emails',
        unknown_email: 'The status of the business email is unknown',
        risky_email: 'The email is risky',
        mailgun_failed: 'Mailgun failed',
      },
      popover: {
        titleVerify: 'Customer approval',
        titleInvalidate: 'Customer unapproval',
        subtitleInvalidate: 'Are you sure you want to unapprove this client?',
        subtitleForceVerifyEmail: 'Are you sure you want to force the approval of this client? The email is not confirmed.',
        subtitleForceVerify: 'Are you sure you want to force the approval of this client?',
        subtitleVerify1: 'By approving this client, the domain',
        subtitleVerify2: 'will be added to the list of accepted domains.',
        subtitleVerify3: 'Clients awaiting approval with this domain will be automatically approved.',
        abortBtn: 'Cancel',
        verifyBtn: 'Approve',
      }
    },
    pagination: {
      btnMore: 'See more customers',
      noMore: 'You have come to the end',
    },
    notification: {
      delete: {
        success: {
          title: 'Success',
          subtitle: 'Customer deleted'
        },
        error: {
          title: 'Error',
          subtitle: 'Customer could not be deleted'
        }
      },
      verification: {
        success: {
          title: 'Success',
          subtitle: 'Customer has been updated'
        },
        error: {
          title: 'Error',
          subtitle: 'Customer could not be updated'
        }
      }
    }
  },
  payVat: {
    yes: 'Yes',
    no: 'No',
    intracom: 'Intra-Community',
  },
  offset: {
    complete: 'Full offset',
    partial: 'Partial offset',
    no: 'No offset',
  },
  partialModeOffset: {
    instantPartial: 'Instant Partial Offset',
    differedPartial: 'Deferred Partial Offset',
    manualPartial: 'Manual Partial Offset',
  },
  vatPayBack: {
    bankTransfer: 'Bank transfer',
    prepaidAccount: 'Credit on prepaid account',
    instantPartial: 'Instant partial offset',
    inCommission: 'N/A',
    no: 'No VAT payback',
  },
  deferredDeadline: {
    atInvoiceReception: 'Upon receipt of the invoice ',
    atEndOfMonth: 'End of the month'
  },
  errorLog: {
    tips: 'Please click the bug icon in the upper right corner',
    description: 'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Document introduction'
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme color',
    showSidebarLogo: 'Sidebar logo',
    fixedHeader: 'Fixed header',
    sidebarTextTheme: 'Sidebar text theme'
  },
  status: {
    waiting: 'Waiting',
    processing: 'In progress',
    fulfilled: 'Fulfilled'
  },
  orderStatus: {
    waiting: 'Waiting for treatment',
    processing: 'Ongoing treatment',
    fulfilled: 'Fulfilled',
    suspended: 'Suspended',
    open: 'Open',
    cancelled: 'Cancelled'
  },
  paymentStatus: {
    refunded: 'Refunded',
    unpaid: 'Unpaid',
    paid: 'Paid',
    late: 'Late'
  },
  deliveryStatus: {
    delivered: 'Delivered',
    in_delivery: 'Partially delivered',
    undelivered: 'Undelivered'
  },
  activeCodes: {
    instant: 'Instant'
  },
  paymentMode: {
    prepaid: 'Prepaid',
    differed: 'Deferred',
    monthly: 'Monthly'
  },
  invoicePer: {
    invoice_per_order: 'Per order',
    invoice_per_month: 'Monthly'
  },
  retrievalMode: {
    title: 'Code recovery',
    api_retrieval: 'API',
    file_retrieval: 'File'
  },
  deliveryMode: {
    title: 'Delivery by',
    api_delivery: 'API',
    file_delivery: 'File'
  },
  kindPrepaidAccount: {
    vat: 'VAT',
    overpayment: 'Over payment',
    orderPayment: 'Bulk',
    topup: 'Bank transfer',
    fix: 'Correction',
    pull: 'API',
    orderPaymentCancellation: 'Bulk cancellation',
    topupCancellation: 'Topup cancellation',
    pullRefund: 'Pull refund',
    cardRefund: 'Card refund'
  },
  orderFormDialog: {
    createOrderBtn: 'Place an order',
    titleUpdate: 'Edit an order',
    titleCreate: 'Create an order',
    infoGeneral: 'General informations',
    giftCard: 'Brands',
    selectGiftCard: 'Select a brand',
    paymentRule: 'Payment rule',
    paymentDelay: {
      title: 'Payment term',
      atReception: 'upon receipt',
      days: 'days'
    },
    drawingCeiling: {
      title: 'Drawing ceiling',
      unlimited: 'unlimited'
    },
    remainingDrawdownLimit: {
      title: 'Remaining draw capacity',
      unlimited: 'unlimited'
    },
    refCustomer: 'External transaction reference',
    opt: '(in option)',
    phrasing: {
      line1: 'Indicate here the transaction reference that you will use for your bank transfer.',
      line2: 'If not fill in please use order reference.'
    },
    retrievalMode: 'Delivery mode',
    retrievalModeOptions: {
      api: 'API',
      file: 'File'
    },
    retrievalModeHelp: 'Choose API if you want these codes to be stored in stock to pull them by API. Choose file, if you want to retrieve the codes via a file.',
    detailOrder: {
      title: 'Detail my order ?',
      yes: 'Yes',
      no: 'No'
    },
    formDetails: {
      alert: {
        title: 'Currently',
        title2: 'on an order of',
        compliant: 'The detail of your order is equal to the total amount of the order in face value.',
        improper: 'The detail is not equal to the total amount of the order in face value.'
      },
      amountCards: 'Number of card(s)',
      amount: 'Amount (€)',
      amountCardsReserved: 'Card to be reserved',
      tooltipCardsReserved: 'The quantity of card in reserve status will be reserved to supply manually or automatically the closed orders of the buyers',
      requiredQuantity: 'Quantity is required',
      amountRequired: 'Amount is required',
    },
    summary: {
      title: 'You currently have a prepaid balance of',
      title2: 'on your account.',
      title3: 'Do you want to use it for this order ?',
      balanceToUse: 'Balance to use',
      btnMax: 'Max',
      amountDetails: 'Amount detail to be paid',
      faceValue: 'Face value',
      commissionTTCPercentage: 'Commission with VAT in %',
      commissionTTCValue: 'Commission with VAT in value',
      commissionHT: 'Commission without VAT',
      credit: 'Credit note',
      creditTips: 'Credit note corresponding to the distribution commission VAT.',
      amountAfterDiscount: 'Amount after discount',
      prepaidAccount: 'Available balance',
      defferedUsage: 'Deferred use',
      remainingAmount: 'Remaining amount due',
      haveCredited: 'Credit note issued',
      pendingPayment: 'Credit note waiting for payment'
    },
    rules: {
      requiredGiftCard: 'Gift Card is required',
      requiredAmount: 'Amount order is required',
      detailsNotEqual: 'The total detail is not equal to the order amount'
    },
    notification: {
      create: {
        title: 'Order created',
        message: 'The order has been created successfully'
      },
      update: {
        title: 'Order updated',
        message: 'The order has been updated'
      },
      error: {
        title: 'Order not created',
        message: 'An error occured'
      },
      delete: {
        title: 'Deleted order',
        message: 'The order has been deleted successfully'
      }
    },
    btnOrder: 'Order',
    btnUpdate: 'Update'
  },
  deliveries: {
    card: {
      title: 'Deliveries',
      btnSee: 'Detail / Download',
      totalOfCodes: 'Total of codes',
      totalOfCodesByAPI: 'Distributed by API',
      totalOfCodesByFile: 'Distributed by File',
      file: 'File',
      downloaded: 'Téléchargé',
      yes: 'Oui',
      partially: 'Partiellement',
      no: 'Non'
    },
    dialog: {
      title: 'My deliveries',
      files: {
        codeFileBlank: 'No file generated',
        status: {
          title: 'Status',
          generated: 'Ready to download',
          notGenerated: 'Not generated',
        },
        detail: {
          title: 'Delivery detail',
        },
        download: {
          firstDownload: 'First downloaded on',
          lastDownload: 'Last downloaded on',
        },
        btn: {
          launchDownload: 'Launch download',
          generatePDFBtn: 'Generate PDFs',
          fileGenerating: 'Generation in progress...',
          generateAndDonwload: 'Generate and download the file',
          confirm: {
            title: 'Are you sure you want to generate a code file without PDF?',
            opt1: 'Cancel',
            opt2: 'Confirm',
          }
        }
      }
    }
  },
  createBuyerDialog: {
    createTitle: 'Creation of a buyer',
    updateTitle: 'Update a buyer',
    nameInput: `Namne of the buyer`,
    codeInput: 'Buyer prefix',
    rcsInput: 'Trade and company register',
    intraVatNumberInput: 'Intra-community VAT N°',
    sirenInput: 'Trade and company registry N°',
    createNewAccount: 'Create a user account for this buyer',
    accountFirstNameInput: 'First name',
    accountLastNameInput: 'Last name',
    accountEmailInput: 'Email',
    title2: 'Secondary informations',
    logoInput: `Buyer logo`,
    locationNameInput: 'Company name',
    locationLine1Input: 'Address',
    locationLine2Input: `Additional address`,
    locationZipCodeInput: 'Postal code',
    locationCityCodeInput: 'City',
    locationStateCodeInput: 'Country',
    abortBtn: 'Cancel',
    confirmBtn: `Confirm`,
    updateBtn: `Update`,
    createAccountBtn: `Create the account`,
    rules: {
      name: `The name of the buyer is required`,
      code: 'The buyer prefix is required',
      rcs: 'Trade and company register is required',
      intraVatNumber: 'Intra-community VAT N° is required',
      siren: 'Trade and company registry N° is required',
      locationName: 'Company name is required',
      locationLine1: `Address is required`,
      locationZipCode: 'Postal code is required',
      locationCity: `The city is required`,
      locationState: 'The country is required',
      firstname: 'First name is required',
      lastname: 'Last name required',
      email: `Email is required`,
    },
    notifications: {
      success: {
        title: 'Buyer created',
        subtitle: `Buyer created successfully`
      },
      error: {
        title: 'Error',
        subtitle: `The buyer could not be created`
      },
      successUpdate: {
        title: 'Buyer updated',
        subtitle: 'Buyer updated successfully'
      },
      successAccount: {
        title: 'Account created',
        subtitle: `Buyer account has been created`
      },
      errorAccount: {
        title: 'Error',
        subtitle: `The buyer account could not be created`
      }
    }
  },
  topupBuyerDialog: {
    title: 'Top-up',
    amountInput: 'Amount (€)',
    referenceInput: 'Bank transfer reference',
    sendAtInput: 'Performed on',
    sendAtPlaceholder: 'Select date and time',
    abortBtn: 'Cancel',
    confirmBtn: 'Confirm',
    rules: {
      amount: 'An amount is required',
      reference: 'Bank transfer reference is required',
      sendAt: 'Bank transfer date is required'
    },
    notifications: {
      success: {
        title: `Buyer has been credited`,
        subtitle: 'credited with'
      },
      error: {
        title: `An error has occured`,
        subtitle: `The buyer could not be credited`
      }
    }
  },
  customerDialog: {
    approvedAuto: 'Client automatically approved',
    approvedManual: 'Client manually approved',
    createTitle: 'New customer',
    updateTitle: `Customer update`,
    firstnameInput: 'First name',
    lastnameInput: 'Last name',
    businessEmailInput: 'Business email',
    checkEmailBtn: 'Check email',
    emailInput: 'Login email',
    emailConfirmation: {
      confirmedByEmail: 'Confirmed by email on',
      notConfirmed: 'Email not confirmed',
      confirmedManually: 'Confirmed manually',
      validationInProgress: 'is in validation'
    },
    emailConfirmationStatus: {
      title: 'Email status',
      accepted: 'In queue',
      rejected: 'Rejected',
      delivered: 'Delivered',
      failed: 'Failed',
      opened: 'Opened',
      clicked: 'Clicked',
      unsubscribed: 'Unsubscribed',
      complained: 'Complained',
      stored: 'Stored',
      on: 'on'
    },
    optinNewsletterInput: 'The client wants to receive newsletters',
    codeCniegInput: 'CNIEG code',
    situationInput: 'Situation',
    retired: 'Retired',
    employee: 'Employee',
    phoneInput: 'Phone number',
    programInput: 'Program',
    abortBtn: 'Cancel',
    confirmBtn: 'Create customer',
    updateBtn: 'Update',
    rules: {
      firstname: 'First name is required',
      lastname: 'Last name is required',
      email: 'Email is required',
      programId: 'Program is required',
    },
    notifications: {
      success: {
        title: 'Success',
        subtitle: 'Customer has been created'
      },
      update: {
        title: 'Success',
        subtitle: 'Customer has been updated'
      },
      error: {
        title: `An error has occurred`,
        subtitle: `Customer could not be created or updated`
      },
      checkEmailSuccess: {
        title: 'Success',
        subtitle: 'Email has been checked with status:'
      },
      checkEmailError: {
        title: 'An error has occurred',
        subtitle: 'Email could not be checked'
      }
    }
  },
  manageDomainDialog: {
    title: 'List of accepted domains',
    selectLabelProgram: 'Domains of program :',
    selectPlaceholderProgram: 'Select a program',
    domainSearchInput: 'Search for a domain',
    popconfirmDelete: {
      title: 'Are you sure you want to delete this domain?',
      cancelBtn: 'Cancel',
      confirmBtn: 'Confirm'
    },
    popoverAdd: {
      title: 'Domain to accept',
      subtitle: 'Enter a domain to add to the list',
      abortBtn: 'Cancel',
      confirmBtn: 'Confirm',
      addDomainBtn: 'Add a domain',
      rules: {
        domainRequired: 'Domain is required',
        domainInvalid: 'Domain is invalid',
      }
    },
    notifications: {
      success: {
        title: 'Succès',
        message: 'Liste de domaine à étè mise à jour'
      },
      error: {
        title: 'Une erreur s\'est produite',
        message: 'Liste de domaine n\'a pas pu être modifier'
      }
    }
  },
  purchaseInfoBuyerDialog: {
    createTitleSeller: `Adding a seller for`,
    updateTitleSeller: `Seller update for`,
    createTitle: 'Assignment of a buyer',
    updateTitle: 'Update a buyer',
    form: {
      titleInformations: 'General information',
      inputSeller: 'Seller',
      inputBuyer: 'Buyer',
      inputPayVat: 'Payment of VAT',
      inputOffset: 'Commission offset mode',
      inputPartialModeOffset: 'VAT payment mode',
      inputDifferedPartialOffset: 'Deadline for payment of VAT (in days)',
      inputVatPayBack: 'VAT offset mode',
      inputPaymentMode: 'Payment mode',
      titleRate: 'Commission rate setup',
      inputRate: 'Commission rate (%)',
      titleDeferred: 'Deferred payment characteristics',
      inputDeferredAmountLimit: 'Deferred limit',
      inputDeferredAmountUsage: 'Deferred available',
      inputDeferredDuration: 'Deferred time',
      inputInvoiceAt: 'Invoice',
      inputApiEnable: 'API enabled ?',
      apiEnableOptions: {
        yes: 'Yes',
        no: 'No',
      },
      btnCreateSeller: `Add seller`,
      btnUpdateSeller: `Update seller`,
      btnCreate: `Add a buyer`,
      btnUpdate: `Update the buyer`,
      btnAbort: 'Cancel',
      notification: {
        success: {
          title: 'Creation of purchase information',
          subtitle: 'The buyer has been added'
        },
        successSeller: {
          title: `Adding a new seller`,
          subtitle: `Seller has been added`
        },
        update: {
          title: 'Done',
          subtitle: 'Purchase information update'
        },
        updateSeller: {
          title: `Success`,
          subtitle: `Update seller purchase information`
        },
        error: {
          title: 'Error',
          subtitle: 'An error occured while adding buyer'
        },
        errorSeller: {
          title: 'Error',
          subtitle: `An error occurred while adding the seller`
        }
      }
    }
  },
  detailsDialog: {
    ref: 'Ref',
    trackingControl: 'Delivery tracking',
    subtitle: 'Follow in real time the status of your delivery',
    status: {
      undelivered: 'Undelivered',
      inDelivery: 'In delivery',
      complete: 'Delivered'
    },
    codeDeliveries: 'Code deliveries',
    deliveriesSubtitle: 'List of code deliveries related to this order',
    deliveriesList: {
      delivery: 'Code(s) delivered: {sum} out of {total} | Code(s) delivered: {sum} out of {total} | Code(s) delivered: {sum} out of {total}',
      activationDate: 'Activation date',
      inactiveCode: 'Inactive code(s)',
      amountTotal: 'Total amount',
      deliveryDetails: 'Order detail',
      equalAmount: '{quantity} card for an amount equal to {amount} | {quantity} card(s) for an amount equal to {amount} | {quantity} card(s) for an amount equal to {amount}',
      quantityReserved: ', including {quantityReserved} reserved card | , including {quantityReserved} reserved cards | , including {quantityReserved} reserved cards',
      empty: 'Not specified',
      downloadFile: 'Download the code file in .CSV'
    },
    remainingDraw: 'Remaining draw',
    subtitleDetail: 'Order detail',
    notSpecified: 'Not specified',
    downloadStandardInvoiceBtn: 'Download the standard invoice',
    downloadCommissionInvoiceBtn: 'Download the commission invoice'
  },
  uploadCodes: {
    errors: {
      CODE_DUPLICATION: 'Code duplication(s) in file',
      CODE_ALREADY_EXISTS: 'Code(s) already delivered in previous order',
      SKU_NOT_FOUND: 'Non-existent face value(s) in order detail',
      CODE_NOT_ORDERED: 'Extra code(s)',
      UNHANDLED_PARSING_ERROR: 'File is not readable.',
      NEGATIVE_AMOUNT: 'Negative face value(s)',
      MISSING_CODE: 'Code(s) missing',
      INVALID_EXPIRY_DATE: 'Expiry date is invalid'
    },
    found: 'error(s) found'
  },
  components: {
    changeLanguageTips: 'Switch Language Success',
    copySuccess: 'The value has been copied',
    copyError: 'The value could not be copied'
  },
  common: {
    noData: "There is no data to display",
  },
  delete: {
    title: 'Are you sure you want to delete this ?',
    abort: 'Cancel',
    confirm: 'Confirm',
    notification: {
      success: {
        title: 'Done',
        subtitle: 'Deleted successfully'
      },
      error: {
        title: 'Error',
        subtitle: 'An error occured'
      }
    }
  },
  roles: {
    seller: 'seller',
    wholesaler: 'wholesaler',
    buyer: 'buyer',
    caretaker: 'caretaker'
  }
}
