export default {
  route: {
    dashboard: 'Tableau de bord',
    orders: 'Commandes',
    brands: 'Enseignes',
    invoices: 'Factures',
    prepaidAccounts: 'Comptes prépayés',
    prepaidAccountView: 'Compte prépayé',
    brandView: 'Vu enseigne',
    profile: 'Mon compte',
    purchase: 'Prise de commande',
    fileMapper: 'File Mapper',
    stocks: 'Gestion des stocks',
    volumeByBuyers: 'Volume par acheteur',
    ratesManagement: 'Gestion des offres',
    notifications: 'Notifications',
    MultiChoiceCards: 'Cartes multi-choix',
    operation: 'Opérations',
    purchaseInfos: 'Information d\'achat',
    buyersManagement: 'Gestion des acheteurs',
    buyersManagementView: 'Gestion de l\'acheteur',
    customers: 'Clients',
    cards: 'Traçabilité des eBons'
  },
  navbar: {
    logOut: 'Déconnexion de market',
    logOutWalleo: 'Déconnexion de market et compte Walleo',
    dashboard: 'Tableau de bord',
    profile: 'Mon compte'
  },
  login: {
    title: 'Formulaire de connexion',
    logIn: 'Se connecter',
    username: 'Email',
    password: 'Mot de passe',
    passwordConfirmation: 'Confirmation du mot de passe',
    any: 'quelconque',
    emailError: 'Veuillez saisir une adresse email valide',
    passwordLengthError: 'Le mot de passe doit contenir au moins 6 charactères',
    wrongCredentialsError: 'E-mail ou mot de passe incorrect',
    forgotPassword: 'Mot de passe oublié'
  },
  profile: {
    cardTitle: 'Mon profil',
    tabs: {
      account: {
        title: 'Compte',
        personalInfo: 'Informations personnelles',
        firstname: 'Prénom',
        lastname: 'Nom',
        email: 'Email',
        password: 'Mot de passe',
        changePassword: 'Modifier le mot de passe',
      }
    }
  },
  dashboard: {
    selectPeriod: "Sélectionnez une période",
    periods: {
      lastMonth: 'Mois dernier',
      today: "Aujourd'hui",
      week: 'Cette semaine',
      month: 'Ce mois-ci',
      year: 'Cette année'
    },
    stats: {
      stat1: 'API / Commandes',
      stat2: 'Montant VF',
      stat3: 'Commission',
    },
    volumesBarChart: {
      title: 'Volumes',
      options: {
        buy: "d'achat",
        sell: 'vente'
      },
      volumesBy: {
        total: 'Total',
        giftCards: 'Enseignes',
        buyers: 'Acheteurs'
      }
    },
    leaderboardChart: {
      title: 'Top des',
      options: {
        giftCards: 'enseignes',
        buyers: 'acheteurs'
      },
    },
    table: {
      ref: 'Réf.',
      amount: 'Montant',
      faceValue: 'Valeur faciale',
      commissionRate: 'Taux de commission HT',
      commissionHT: 'Commission HT',
      commissionTTC: 'Commission TTC',
      date: 'Date',
    },
    hello: 'Bonjour',
    connectedAs: 'Connecté en tant que',
    activityBlock: {
      title: 'Activité',
      subtitle: 'Consultez chaque colonne pour plus de détails',
      turnover: 'chiffre d\'affaires',
      ordersNotProcessed: 'commandes à traiter',
      ordersNotDelivered: 'commandes à livrer',
      ordersUnpaid: 'commandes non payées'
    },
    ordersBlock: {
      title: 'Commandes',
      subtitle: 'Commandes nécéssitant une intervention de votre part',
      noPaymentRecorded: 'Aucun paiement enregistré',
      orderNotProceed: 'La commande n\'a pas été passée en traitement'
    },
    prepaidAccountBlock: {
      prepaidAccount: 'solde disponible',
      creditWaiting: 'avoirs en attente'
    }
  },
  orders: {
    header: {
      title: 'Suivi des commandes',
      subtitle: 'Retrouvez vos commandes sur cette interface',
      btnSwitchToBuyer: 'Voir mes commandes acheteur',
      btnSwitchToSeller: 'Voir mes commandes fournisseur'
    },
    splittedTable: {
      btnSplit: 'Mode fractionné',
      table1: 'Commandes en acheteur',
      table2: 'Commandes en fournisseur',
    },
    buyer: {
      filters: {
        filterBtn: 'Filtrer mes commandes',
        title: 'Filtres',
        subtitle: 'Obtenez des meilleurs résultats en utilisant nos filtres intelligents',
        orderNumber: 'Numéro de commande',
        refCustomer: 'Ref. de transaction client',
        timeInterval: 'Intervalle de temps',
        startDate: 'Date de début',
        to: 'à',
        endDate: 'Date de fin',
        status: 'Statut de commande',
        paymentStatus: 'Statut de paiement',
        deliveryStatus: 'Statut de livraison',
        gitfCard: 'Enseignes',
        buyers: 'Acheteurs',
        buyersInfo: 'Applicable uniquement sur la vue commande des acheteurs',
        applyFilters: 'Appliquer les filtres'
      },
      codes: {
        download: 'Télécharger les codes',
        multipleFiles: 'fichiers de codes sont à télécharger'
      },
      card: {
        ref: 'Ref.',
        buyer: 'Acheteur',
        faceValue: 'Valeur faciale',
        activeFrom: 'Actif à partir du',
        orderPaid: 'Payée',
        orderPaidOn: 'Payée le',
        status: {
          text: 'Statut',
          refunded: 'Remboursée',
          yes: 'Oui',
          partial: 'Partielle',
          no: 'Non',
          inProgress: 'En cours'
        },
        paymentDeadline: 'Échéance de paiement le',
        btnSeeMoreDetails: 'Voir plus de détails',
        exceeding: 'dépassement de',
        orderDelivered: 'Livrée',
        orderDeliveredOn: 'Livrée le',
        detailOrder: 'Veuillez détailler votre commande',
        confirmOrder: 'Confirmer la commande ?',
        proceed: 'Go',
        notNow: 'Pas maintenant',
        processOrder: 'Traiter ma commande',
        waitingDetails: 'En attente de détail',
        btnPaymentWithBalance: 'Payer',
        paymentWithBalanceOptions: {
          opt1: 'Confirmer',
          opt2: 'Annuler'
        },
        orderInfo: {
          phrasing1: 'Afin de faciliter l’identification de votre futur paiement de',
          phrasing2: ', merci d’utiliser',
          phrasing3: 'comme référence de virement.'
        },
        menu: {
          details: 'Détails',
          edit: 'Editer',
          delete: 'Supprimer',
          resume: 'Reprendre',
          suspend: 'Suspendre'
        },
        deleteButtonConfirm: {
          title: 'Êtes-vous sûr de vouloir supprimer cette commande ?',
          opt1: 'Confirmer',
          opt2: 'Annuler'
        },
      }
    },
    seller: {
      header: {
        title: 'Suivi des commandes',
        subtitle: 'Gardez un oeil sur vos livraisons'
      },
      filters: {
        filterBtn: 'Filtrer mes commandes',
        title: 'Filtres',
        subtitle: 'Obtenez des meilleurs résultats en utilisant nos filtres intelligents',
        orderNumber: 'Numéro de commande',
        timeInterval: 'Intervalle de temps',
        startDate: 'Date de début',
        to: 'à',
        endDate: 'Date de fin',
        orderStatus: 'Statut de livraison',
        applyFilters: 'Appliquer les filtres'
      },
      status: {
        complete: 'Livraison complète',
        partial: 'Livraison partielle',
        waiting: 'En attente de livraison',
        errors: {
          title: 'Échec de la livraison',
          details: 'Aucune erreur trouvée | {count} erreur trouvée | {count} erreurs trouvées'
        }
      },
      card: {
        faceValue: 'Valeur faciale',
        progressBarTooltip: 'Vous avez livré un total de {amount} code(s) sur {total} code(s)',
        cardDistributions: 'Détail de la livraison',
        cardDistributionsDetails: 'carte(s) d\'un montant égal à',
        cardDistributionsEmpty: 'Non renseignée',
        deliverCodes: 'Livrer les codes'
      },
      codesDialog: {
        title: 'Téléversement du fichier de codes',
        subtitle: 'Commande #',
        step1: {
          note: 'Assurez-vous que votre fichier de codes ne contient pas d\'erreurs et que votre File Mapper est correctement configuré. La configuration de la date d\'expiration des codes se passe après cette étape.'
        },
        step2: {
          codeActivationDate: 'Date d\'activation des codes',
          placeholderActivationDate: 'Selectionnez une date et un horaire d\'activation des codes',
          note: 'Une fois la date d\'activation des codes renseignée, votre fichier sera prêt à être traité.',
          warning: 'Attention, après avoir cliqué sur "Confirmer la livraison" les codes sont intégralement mis à disposition du client.',
          cancelBtn: 'Annuler',
          confirmBtn: 'Confirmer la livraison',
          processingBtn: 'Traitement...'
        },
        step3: {
          deliveryErrors: {
            title: 'La livraison a échoué',
            subtitle: 'Le fichier que vous venez de télécharger contient des erreurs. Vérifiez aussi que le File Mapper utilisé est correct. Retrouvez les erreurs ci-dessous',
            backToOrderBtn: 'Revenir aux commandes',
            newDeliveryBtn: 'Nouvelle livraison'
          },
          deliverySuccess: {
            title: 'La commande #{number} a été livrée',
            codeActivationDate: 'Les codes seront actifs à partir du :',
            backToOrderBtn: 'Revenir aux commandes'
          }
        },
        deliveryCodesDetails: {
          title: 'Voir les détails de la livraison'
        },
        manageFileMapper: {
          title: 'Gérer mes File Mappers',
          byDefaultBtn: 'Mettre par défaut',
          notification: {
            success: {
              title: 'File Mapper mis à jour',
              subtitle: 'Le file mapper par défaut a été mis à jour'
            },
            error: {
              title: 'Erreur dans le File Mapper',
              subtitle: 'Impossible de changer le File Mapper par défaut'
            }
          }
        },
        handleDeliveryErrors: {
          title: 'Un total de {amount} erreurs ont été trouvées lors de la livraison',
          subtitle: '- Veuillez corriger les erreurs avant de refaire une livraison',
          moreDetailsBtn: '- Afficher plus de détails',
          errorLine: 'Erreur ligne'
        }
      },
      rules: {
        activationDateRequired: 'Date d\'activation requise',
        codeFileRequired: 'Fichier de codes requis'
      }
    },
    wholesaler: {
      btnWholesalerOrders: 'Commandes des fournisseurs',
      btnSeeBuyersOrders: 'Commandes des acheteurs',
      btnLaunchDelivery: 'Livrer la commande',
      seller: 'Fournisseur',
    },
    notification: {
      delete: {
        title: 'Opération réussie',
        message: 'Suppression effectuée'
      },
      resumeSuccess: {
        title: 'Succès',
        message: 'La commande a été mise en cours de traitement'
      },
      resumeError: {
        title: 'Erreur',
        message: 'La commande n\'a pas pu être mise en cours de traitement'
      },
      suspendSuccess: {
        title: 'Succès',
        message: 'La commande a été suspendue'
      },
      suspendError: {
        title: 'Erreur',
        message: 'La commande n\'a pas pu être suspendue'
      },
      deliverySuccess: {
        title: 'Commande en livraison',
        message: 'La livraison de la commande est lancée'
      },
      deliveryError: {
        title: 'Impossible de livrer la commande',
        message: 'La livraison n\'a pas pu être lancée'
      },
      downloadError: {
        title: 'Le téléchargement à échoué',
        message: 'Une erreur s\'est produite lors du téléchargement'
      }
    },
    noMore: 'Vous êtes arrivé à la fin',
    noResults: 'Aucun résultat',
    btnMore: 'Charger plus de commandes'
  },
  invoices: {
    header: {
      title: 'Mes factures',
      subtitle: 'Retrouvez vos factures émises pour chaque commande',
      type: {
        seeInvoicesOfBtn: 'Voir les factures',
        buyers: 'acheteurs',
        sellers: 'vendeurs'
      },
      filters: {
        filterBtn: 'Filtrer mes factures',
        title: 'Filtres',
        subtitle: 'Obtenez des meilleurs résultats en utilisant nos filtres intelligents',
        paymentMode: 'Mode de paiement',
        invoicePer: 'Mode de facturation',
        invoicePerOrder: 'À la commande',
        invoicePerMonth: 'Mensuelle',
        paymentModePrepaid: 'Prépayé',
        paymentModeDiffered: 'Différé',
        invoiceNumber: 'Numéro de facture',
        timeInterval: 'Intervalle de temps',
        startDate: 'Date de début',
        to: 'au',
        endDate: 'Date de fin',
        paymentStatus: 'Statut de paiement',
        orderNumber: 'N° de commande',
        sellers: 'Vendeurs',
        buyers: 'Acheteurs',
        applyFilters: 'Appliquer les filtres'
      },
      extractInvoicesBtn: 'Extraction des factures'
    },
    table: {
      col2: 'Date',
      col3: 'N° Facture',
      col4: 'Mode de facturation',
      col5: 'Montant',
      col6: 'Statut',
      seeMoreBtn: 'Voir plus de factures'
    },
    asidePanel: {
      title: 'Détails de la facture',
      closeBtn: 'Fermer',
      multiBrands: 'Multi enseignes',
      overpaymentOf: 'Trop-perçu de',
      underpaidOf: 'Restant dû de',
      type: 'Type de facture',
      standard: 'Standard',
      commission: 'Commission',
      issuedOn: 'Émise le',
      dueDate: 'Échéance',
      payment: 'Paiement',
      amountToBePaid: 'Montant total',
      balance: 'Solde dû',
      number: 'N°',
      commentComponent: {
        comment: 'Commentaire',
        placeholder: 'Tapez ici pour ajouter un commentaire...',
        notSpecified: 'Aucun commentaire',
        btnAdd: 'Ajouter un commentaire',
        btnEdit: 'Editer',
        btnSave: 'Sauvegarder',
        btnCancel: 'Annuler',
        notification: {
          success: {
            title: 'Succès',
            subtitle: 'Commentaire mis à jour'
          },
          error: {
            title: 'Erreur',
            subtitle: 'Une erreur s\'est produite'
          }
        }
      },
      seeAndDownloadBtn: 'Voir et télécharger ma facture',
      sendInvoiceBtn: 'Téléverser ma facture',
      modifyInvoiceBtn: 'Modifier ma facture',
      popoverValue: {
        number: {
          title: 'Édition du numéro de facture',
          placeholder: 'Renseignez le numéro de facture',
        },
        customNumber: {
          title: 'Ajouter une référence',
          titleEdit: 'Édition de la référence',
          placeholder: 'Renseignez la référence',
          btn: 'Ajouter une référence',
        },
        error: {
          number: 'Le numéro de facture est requis',
          customNumber: 'La référence est requise',
        }
      },
      paymentStatusResultSubtitles: {
        paid: 'Aucune action requise',
        unpaid: 'Veuillez procéder au paiement',
        late: 'Vous avez un retard de paiement'
      },
      relatedOrders: {
        title: 'Commande(s) liée(s)',
        ref: 'Ref.',
        notSpecified: 'Non renseignée',
        seeMoreBtn: 'Voir plus de commandes',
        inputOrderNumber: 'Numéro de commande'
      },
      relatedBankTransfer: {
        title: 'Virement(s) bancaire(s) lié(s)',
        total: 'Total réglé',
        empty: 'Aucun virement bancaire émis'
      },
      seePaymentProof: 'Consultez la preuve de paiement'
    },
    uploadInvoiceDialog: {
      title: 'Téléverser votre facture',
      invoiceNumber: 'N° de facture',
      invoice: 'Facture',
      mandatoryFieldPDF: '(PDF uniquement)',
      cancelBtn: 'Annuler',
      uploadInvoiceBtn: 'Téléverser ma facture',
      numberIsRequired: 'N° de facture requis',
      fileIsRequired: 'Fichier PDF requis',
      notification: {
        success: {
          title: 'Facture téléverser',
          message: 'La facture a été correctement téléversée'
        },
        error: {
          title: 'Une erreur s\'est produite',
          message: 'La facture n\'a pas pu être téléversée'
        }
      }
    }
  },
  extractInvoicesDialog: {
    title: 'Extraction des factures',
    inputMonths: 'Dates des factures à extraire',
    to: 'à',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    cancelBtn: 'Annuler',
    extractBtn: 'Télécharger l\'extraction en csv',
    notification: {
      error: {
        title: 'Erreur',
      }
    }
  },
  prepaidAccount: {
    header: {
      title: 'Vu d\'ensemble des comptes prépayés',
      subtitle: 'Retrouvez sur cette page les comptes prépayés de vos clients',
      title2: 'Compte prépayé',
      subtitle2: 'Retrouvez sur cette page les mouvements du compte prépayé de votre client',
      stats: {
        stats1: 'solde disponible'
      },
      filters: {
        filterBtn: 'Plus de filtres',
        title: 'Filtres',
        subtitle: 'Obtenez des meilleurs résultats en utilisant nos filtres intelligents',
        orderNumber: 'Numéro de commande',
        timeInterval: 'Intervalle de temps',
        startDate: 'Date de début',
        to: 'au',
        endDate: 'Date de fin',
        giftCard: 'Enseignes',
        seller: 'Vendeur',
        reason: 'Motif',
        extraction: {
          title: 'Rapports mensuel',
          subtitle: 'Téléchargez vos rapports sous format csv',
          btn: 'Télécharger',
          notification: {
            error: {
              title: 'Erreur lors de l\'extraction',
              message: 'Renseignez une période'
            }
          }
        },
        applyFilters: 'Appliquer les filtres',
        setGlobalViewBtn: 'Vue globale',
        setSellersViewBtn: 'Vue par fournisseur'
      },
      alert: {
        title: 'alerte de solde',
        dialog: {
          title: 'Ajout d\'une alerte de solde disponible',
          subtitle: 'Un email vous sera envoyé une fois le montant saisi dépassé',
          rules: {
            required: 'Le montant est requis'
          }
        },
        notification: {
          success: {
            title: 'Success',
            subtitle: 'Available balance alert added successfully'
          },
          error: {
            title: 'Error',
            subtitle: 'Balance alert could not be added'
          },
        }
      }
    },
    extractDialog: {
      title: 'Rapports mensuel',
      alert: {
        title: 'Information',
        subtitle: 'Les filtres ne s\'appliquent pas à lors du téléchargement du rapport'
      },
      inputMonths: 'Mois du rapport à télécharger',
      currentMonth: 'Mois en cours',
      cancelBtn: 'Annuler',
      extractBtn: 'Télécharger le rapport en csv',
    },
    table: {
      ref: 'Réf.',
      buyer: 'Acheteur',
      requestId: 'ID Requête',
      giftCard: 'Enseigne',
      bankTransfer: 'Virement bancaire',
      amount: 'Montant',
      faceValue: 'Valeur faciale',
      commissionRate: 'Taux de commission HT',
      commissionHT: 'Commission HT',
      commissionTTC: 'Commission TTC',
      date: 'Date',
      btnMore: 'Voir plus de mouvements',
      btnMoreBalance: 'Voir plus de balance',
      paginationOn: 'sur',
      noResult: 'Aucun résultat'
    },
    processing: 'Traitement'
  },
  stock: {
    header: {
      title: 'Mon stock eBons',
      subtitle: 'Retrouvez les stocks de chacune de vos enseignes',
      stats: {
        stats1: 'Commande(s) à honorer'
      },
      filters: {
        filterBtn: 'Filtrer/Trier le stock',
        title: 'Filtres',
        subtitle: 'Obtenez des meilleurs résultats en utilisant nos filtres intelligents',
        giftCard: 'Enseignes',
        sortStock: 'Trier le stock',
        sortStockSubtitle: 'Triez facilement votre stock pour éviter les ruptures stocks',
        sortBy: 'Trier par',
        options: {
          name: 'Nom de l\'enseigne',
          delivery: 'Achat prioritaire'
        },
        applyFilters: 'Appliquer les filtres'
      }
    },
    card: {
      faceValueStock: 'Stock en valeur faciale',
      modeTooltip: 'Mode d\'approvisionnement',
      orderToFulfill: 'Commande(s) à honorer',
      amountDetails: 'Détail du montant',
      equalAmount: '{quantity} carte d\'un montant égal à {amount} | {quantity} cartes d\'un montant égal à {amount} | {quantity} cartes d\'un montant égal à {amount}',
      subtotal: 'Sous-total',
      createOrderBtn: 'Passer une commande',
      editOrderBtn: 'Éditer la commande',
      faceValue: 'Valeur faciale',
      toOrder: 'À commander',
      availableStock: 'Stock disponible',
      available: 'Disponible',
      sharedStock: 'Stock partagé',
      shared: 'Partagé',
      reservedStock: 'Stock réservé',
      reserved: 'Réservé'
    },
    noMore: 'Vous êtes arrivé à la fin',
    noResults: 'Aucun résultat',
    btnMore: 'Charger plus d\'enseignes',
    notification: {
      success: {
        title: 'Commande créée',
        message: '#{number} a été placée dans vos commandes'
      }
    }
  },
  volumeByBuyers: {
    header: {
      title: 'Volume d\'achat',
      subtitle: 'Visualisez vos volumes d\'achat',
      filters: {
        filterBtn: 'Filtrer les acheteurs',
        title: 'Filtres',
        subtitle: 'Obtenez des meilleurs résultats en utilisant nos filtres intelligents',
        findBuyer: 'Rechercher un acheteur',
        selectYear: 'Sélectionner une année',
        applyFilters: 'Appliquer les filtres'
      }
    },
    table: {
      col1: 'Acheteur',
      col2: 'Taux appliqué',
      col3: 'Volume total',
      col4: 'Volume', // Ex: Volume (2022)
      col5: 'Historique (aperçu)',
      empty: 'Aucun acheteur'
    }
  },
  fileMapper: {
    title: 'Créer un nouveau File Mapper',
    uploadBlock: {
      title: 'Glissez / Déposez votre ficher',
      or: 'Ou',
      title2: 'Cliquez ici pour ouvrir l\'explorateur de fichiers',
      title3: '(Détection automatique des en-têtes de votre fichier)',
      errorMultipleFiles: 'Un seul fichier ne peut être téléchargé',
      errorUnreadbleFile: 'Fichier non supporté'
    },
    manualBtn: 'Ajouter manuellement',
    subtitleManual: 'Ajoutez manuellement les noms de vos en-têtes de colonnes tout en les affectant aux variables correspondantes.',
    table: {
      col1: 'Généré le',
      col2: 'Défaut',
      col3: 'Centimes'
    },
    fileMapperDialog: {
      new: 'Création du File Mapper',
      edit: 'Mettre à jour le File Mapper',
      info: {
        title: 'Information',
        content: 'Les variables Code et Montant sont obligatoires pour générer le File Mapper. Les autres variables disponibles sont optionnelles.'
      },
      requiered: 'Requis',
      selectHeader: 'Sélectionnez un en-tête',
      headerName: 'Nom en-tête',
      columnNumber: 'Numéro de la colonne',
      optional: 'Opt.', // 3 lettres MAX
      code: 'Code',
      amount: 'Montant',
      pinCode: 'Code PIN',
      expiration: 'Expiration',
      configuration: {
        fileFormat: 'Format du fichier',
        fileFormatLabel: 'Votre fichier possède t-il des en-têtes ?',
        additionalSetups: 'Configurations supplémentaires',
        separator: 'Caractère de séparation des colonnes',
        separatorLabel: 'Indiquez le caractère qui sépare chaque colonne de votre fichier',
        byDefault: 'Par défaut:',
        comma: 'virgule',
        dateFormat: 'Format de la date',
        dateFormatLabel: ' Renseignez le format de la date d\'éxpiration',
        year: 'Année',
        month: 'Mois',
        day: 'Jour',
        exemple: 'Exemple',
        switchByDefault: 'File Mapper par défaut',
        switchAmountInCentimes: 'Montant exprimé en centimes'
      },
      cancelBtn: 'Annuler',
      editFileMapper: 'Mettre à jour',
      createFileMapper: 'Créer un File Mapper',
      errors: {
        codeRequiered: 'La variable code doit être assignée',
        amountRequiered: 'La variable montant doit être assignée'
      },
      notification: {
        success: {
          title: 'File Mapper créé',
          subtitle: 'Le File Mapper a été créé avec succès'
        },
        update: {
          title: 'File Mapper mis à jour',
          subtitle: 'Le File Mapper a été mis à jour avec succès'
        },
        error: {
          title: '',
          subtitle: ''
        }
      }
    }
  },
  brands: {
    header: {
      title: 'Enseignes',
      subtitle: 'Retrouvez les informations de vos enseignes',
      productID: 'ID produit',
      filters: {
        giftCard: 'Chercher une enseigne'
      },
    },
    card: {
      copyID: 'Copier ID',
      copyProductID: 'Copier ID produit',
      details: 'Détails'
    },
    table: {
      noMore: 'Vous êtes arrivé à la fin',
      noResults: 'Aucun résultat',
      btnMore: 'Charger plus d\'enseignes',
      paginationOn: 'sur'
    },
    view: {
      generalInformations: 'Informations générales',
      description: 'Description',
      descriptionOnWallet: 'Cette description sera affichée sur la fiche enseigne du Wallet',
      notSpecified: 'Non renseignée',
      baseline: 'Baseline / Signature de l\'enseigne',
      useForPDF: 'Utilisée pour la génération des PDF',
      use: 'Utilisation',
      howToUseEbon: 'Comment utiliser un eBon',
      useEbonOn: 'Utiliser un eBon',
      online: '(en ligne)',
      offline: '(en magasin)',
      termsOfUse: 'Conditions d\'utilisation',
      characteristic: 'Caractéristique',
      cumulativeOnline: {
        title: 'Cumulable en ligne',
        opt1: 'Oui',
        opt2: `jusqu'à`,
        opt3: 'Illimitée',
        opt4: 'Non'
      },
      cumulativeOffline: {
        title: 'Cumulable en magasin',
        opt1: 'Oui',
        opt2: `jusqu'à`,
        opt3: 'Illimitée',
        opt4: 'Non'
      },
      usable: {
        title: 'Utilisation',
        opt1: 'En ligne / en magasin',
        opt2: 'Uniquement en ligne',
        opt3: 'Uniquement en magasin'
      },
      splittable: {
        title: 'Sécable',
        opt1: 'Oui',
        opt2: 'Non'
      },
      validity: {
        title: 'Validité',
        opt1: 'Illimitée'
      },
      pinCode: {
        title: 'Code PIN',
        opt1: 'Oui',
        opt2: 'Non'
      },
      pinCodeConcatenation: {
        title: 'Concaténation code PIN',
        opt1: 'Oui',
        opt2: 'Non'
      },
      api: {
        title: 'API',
        opt1: 'Oui',
        opt2: 'Non (Mode stock)'
      },
      barcodeSymbology: 'Type de code-barres',
      others: 'Autres',
      onlineUrl: 'URL pour accéder à la boutique en ligne',
      linkToAccess: 'Lien pour accéder à la boutique en ligne de l\'enseigne',
      partnerStore: 'Boutique(s) partenaire(s)',
      partnerStoreList: 'Liste des boutiques partenaires pour l\'utilisation des eBons',
      sku: {
        title: 'Valeur(s) liée(s)',
        table: {
          col1: 'Type de valeur',
          col2: 'Valeur',
          col3: 'Valeur libre (minimum)',
          col4: 'Valeur libre (maximum)'
        },
        options: {
          yes: 'Valeur libre',
          no: 'Fixe'
        }
      },
      images: {
        title: `Banque d'image`,
        squareLogo: 'Logo carré',
        squareLogoDetails: 'Taille originale : 250x250px',
        bannerImage: 'Logo bannière',
        bannerImageDetails: 'Taille originale : 550x250px',
        cardImage: 'Image carte',
        cardImageDetails: `Aucune taille par défaut (16/9)`,
        downloadBtn: 'Télécharger'
      },
      period: {
        year: '{year} an | {year} ans | {year} ans',
        month: '{month} mois | {month} mois | {month} mois',
        separator: 'et'
      }
    }
  },
  purchaseInfo: {
    header: {
      title: 'Gestion des taux de commission',
      subtitle: 'Gérer les taux de commission des fournisseurs et des acheteurs'
    },
    stats: {
      title: 'Enseignes en gestion'
    },
    wrapper: {
      giftCardsList: {
        title: 'Enseignes',
        subtitle: 'Aucune enseigne active | Une enseigne active | {total} enseignes actives',
        filters: {
          giftCards: 'Chercher une enseigne',
          sellers: 'Chercher un fournisseur'
        }
      },
      title: 'Gestion des taux de l\'enseigne',
      subtitle: 'Aucun fournisseur trouvé | Un fournisseur trouvé | {total} fournisseurs trouvés',
      sellersList: {
        btnAddSeller: 'Ajouter un fournisseur',
        titleBtnOpen: `Voir plus d'informations`,
        titleBtnClose: `Voir moins d'informations`,
        lastUpdate: 'Dernière modification',
        never: 'Jamais',
        informations: {
          title: 'Informations générales du fournisseur',
          col1: 'Taux de commission',
          col2: 'Paiement de la TVA',
          col3: 'Mode de paiement de la commission',
          col4: 'Mode de paiement de la TVA',
          col5: 'Mode de règlement au fournisseur',
          col6: 'API',
          col7: 'Mode de facturation'
        },
        characteristicDeferred: {
          title: 'Caractéristiques du paiement différé',
          col1: 'Plafond du différé',
          col2: 'Différé utilisé',
          col3: 'Délai de paiement',
          col4: 'Conditions',
        },
        buyersTable: {
          title: 'Informations des acheteurs',
          btnAdd: 'Ajouter un acheteur',
          col1: 'Acheteur',
          col2: 'Taux de commission',
          col3: 'Marge',
          col4: 'TVA',
          col5: 'Mode de compensation de la commission',
          col6: 'Mode de paiement'
        }
      }
    },
    rateChangeSchedule: {
      popover: {
        title: 'Planification du taux',
        inputBuyer: 'Choisir un acheteur',
        inputAmount: 'Saisissez un taux',
        inputTime: 'Choisissez un jour et une heure',
        btnSave: 'Enregistrer',
        btnAdd: 'Planifier un taux',
        rules: {
          buyer: 'L\'acheteur est requis',
          amount: 'Le montant est requis',
          time: 'La date et l\'heure sont requises',
        },
        notification: {
          success: {
            title: 'Taux planifié',
            subtitle: 'Le taux a été planifié avec succès'
          },
          update: {
            title: 'Mise à jour',
            subtitle: 'Le taux planifié a été modifié avec succès'
          },
          error: {
            title: 'Erreur',
            subtitle: 'Le taux n\'a pas pu être planifié'
          }
        }
      },
      card: {
        title: 'Taux planifié',
        on: 'le',
        notification: {
          success: {
            title: 'Succès',
            subtitle: 'Le taux planifié a été supprimé'
          },
          error: {
            title: 'Erreur',
            subtitle: 'Le taux planifié n\'a pas pu être supprimé'
          }
        }
      }
    }
  },
  buyersManagement: {
    header: {
      title: 'Gestion des acheteurs',
      subtitle: 'Gérez le solde de vos différents acheteurs',
      btnAdd: 'Ajouter un acheteur'
    },
    stats: {
      title: 'Acheteurs en gestion'
    },
    card: {
      remainingBalance: 'solde disponible',
      btnTopup: 'Créditer',
      btnEditDetails: 'Éditer / Détails'
    },
    table: {
      noMore: 'Vous êtes arrivé à la fin',
      noResults: 'Aucun résultat',
      btnMore: 'Charger plus d\'acheteurs',
    }
  },
  customers: {
    header: {
      title: 'Gestion des clients',
      subtitle: 'Retrouvez les informations personnelles de vos clients',
      btnAdd: 'Ajouter un client',
      btnDomains: 'Liste des domaines acceptés',
      btnAddDomain: 'Ajouter un domaine',
      filters: {
        sortByDate: 'Trier par date de création',
        sortByDateOptions: {
          desc: 'Clients les plus récents',
          asc: 'Clients les plus anciens'
        },
        sortByUpdate: 'Trier par date d\'activitée',
        sortByUpdateOptions: {
          desc: 'Activitées les plus récentes',
          asc: 'Activitées les plus anciennes'
        },
        sortByName: 'Trier par ordre alphabétique',
        sortByNameOptions: {
          asc: 'Nom par ordre alphabétique (A-Z)',
          desc: 'Nom par ordre alphabétique (Z-A)'
        },
        moreFiltersBtn: 'Plus de filtres',
        firstname: 'Prénom',
        lastname: 'Nom',
        email: 'Email',
        businessEmail: 'Email professionnel',
        confirmed: 'Email confirmé (Connexion)',
        confirmedOptions: {
          notConfirmed: 'Non confirmé',
          confirmed: 'Confirmé'
        },
        approval: 'Approbation',
        approvalOptions: {
          waiting: 'En attente de confirmation du mail de login',
          verification_failed: 'Échec de l\'approbation',
          verified: 'Approuvé'
        },
        registrationFailureReason: 'Raison de l\'échec de l\'approbation',
        registrationFailureReasonOptions: {
          no_reason: 'Aucune raison',
          bad_email: 'Email non valide',
          domain_unknown: 'Domaine inconnu',
          catch_all_domain: 'Domaine accepte tous les emails',
          unknown_email: 'Statut de l\'email professionnel inconnu',
          risky_email: 'Email risqué',
          mailgun_failed: 'Mailgun échoué'
        },
        dateRange: 'Date de création',
        dateRangePlaceholder: {
          start: 'Date de début',
          end: 'Date de fin',
          separator: 'à'
        },
        program: 'Programme'
      }
    },
    stats: {
      title: 'Total des clients'
    },
    card: {
      phone: 'Téléphone',
      situation: 'Situation',
      retired: 'Retraité',
      employee: 'Salarié',
      program: 'Programme',
      approved: 'Approuvé',
      yes: 'Oui',
      auto: 'Auto',
      manual: 'Manuel',
      waiting: 'En attente de confirmation du mail de login',
      no: 'Non',
      notSpecified: 'Non spécifié',
      btnEdit: 'Éditer',
      confirmedShort: 'Email confirmé',
      confirmedLong: 'Email confirmé (Connexion)',
      confirmedOptions: {
        notConfirmed: 'Non',
        confirmed: 'Oui le'
      },
      btnVerify: 'Approuver',
      btnDelete: 'Supprimer',
      popConfirmDelete: {
        title: 'Êtes-vous sûr de vouloir supprimer ce client ?',
        confirmBtn: 'Confirmer',
        cancelBtn: 'Annuler',
      },
      btnInvalidate: 'Désapprouver',
      popoverFailure: {
        no_reason: 'Aucune raison',
        domain_unknown: 'Le domaine n\'est pas référencé pour Avantages IEG',
        bad_email: 'L\'email professionnel n\'existe pas',
        catch_all_domain: 'Le domaine accepte tous les emails',
        unknown_email: 'Le statut de l\'email professionnel est inconnu',
        risky_email: 'L\'email est risqué',
        mailgun_failed: 'Mailgun a échoué',
      },
      popover: {
        titleVerify: 'Approuver le client',
        titleInvalidate: 'Désapprouver le client',
        subtitleInvalidate: 'Êtes-vous sûr de vouloir désapprouver ce client ?',
        subtitleForceVerifyEmail: 'Êtes-vous sûr de vouloir forcer l\'approbation de ce client ? Son email n\'est pas confirmé.',
        subtitleForceVerify: 'Êtes-vous sûr de vouloir forcer l\'approbation de ce client ?',
        subtitleVerify1: 'En approuvant ce client, le domaine',
        subtitleVerify2: 'sera ajouté a liste des domaines accéptés.',
        subtitleVerify3: 'Les clients en attente d\'approbation avec ce domaine seront automatiquement approuvés.',
        abortBtn: 'Annuler',
        verifyBtn: 'Approuver',
      }
    },
    pagination: {
      btnMore: 'Voir plus de clients',
      noMore: 'Vous êtes arrivé à la fin',
    },
    notification: {
      delete: {
        success: {
          title: 'Succès',
          subtitle: 'Client supprimé'
        },
        error: {
          title: 'Erreur',
          subtitle: 'Le client n\'a pas pu être supprimé'
        }
      },
      verification: {
        success: {
          title: 'Succès',
          subtitle: 'Client mis à jour'
        },
        error: {
          title: 'Erreur',
          subtitle: 'Le client n\'a pas pu être mis à jour'
        }
      }
    }
  },
  payVat: {
    yes: 'Oui',
    no: 'Non',
    intracom: 'Intracom',
  },
  offset: {
    complete: 'Compensation totale',
    partial: 'Compensation partielle',
    no: 'Pas de compensation',
  },
  partialModeOffset: {
    instantPartial: 'Compensation partielle instantanée',
    differedPartial: 'Compensation partielle différée',
    manualPartial: ' Compensation partielle manuelle',
  },
  vatPayBack: {
    bankTransfer: 'Virement bancaire',
    prepaidAccount: 'Crédit sur compte prépayé',
    instantPartial: ' Compensation partielle instantanée',
    inCommission: 'N/A',
    no: 'Pas de remboursement de la TVA',
  },
  deferredDeadline: {
    atInvoiceReception: 'À réception de facture',
    atEndOfMonth: 'À la fin du mois'
  },
  errorLog: {
    tips: 'Cliquez sur l\'icône avec l\'insecte en haut à droite',
    description: 'Le système de gestion propose un formulaire d\'authentification en Single Page Application, qui améliore l\'expérience utilisateur, mais qui peut aussi augmenter les risques de rencontrer des problèmes avec la page, une simple négligeance peut entraîner un dysfonctionnement de la page. Heureusement Vue propose une méthode pour gérer les exceptions, avec laquelle il est possible de gérer les erreurs et avoir un reporting des exceptions.',
    documentation: 'Introduction'
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    showSidebarLogo: 'Sidebar Logo',
    fixedHeader: 'Fixed Header',
    sidebarTextTheme: 'Sidebar Text Theme'
  },
  status: {
    waiting: 'En attente',
    processing: 'En cours',
    fulfilled: 'Complétée'
  },
  orderStatus: {
    waiting: 'En attente de traitement',
    processing: 'En cours de traitement',
    fulfilled: 'Honorée',
    suspended: 'Suspendue',
    open: 'Ouverte',
    cancelled: 'Annulée'
  },
  paymentStatus: {
    refunded: 'Remboursée',
    unpaid: 'Non payée',
    paid: 'Payée',
    late: 'En retard'
  },
  deliveryStatus: {
    delivered: 'Livrée',
    in_delivery: 'Partiellement livrée',
    undelivered: 'Non livrée'
  },
  activeCodes: {
    instant: 'Instantané'
  },
  paymentMode: {
    prepaid: 'Comptant',
    differed: 'Différé',
    monthly: 'Mensuel'
  },
  invoicePer: {
    invoice_per_order: 'À la commande',
    invoice_per_month: 'Mensuelle'
  },
  retrievalMode: {
    title: 'Récupération des codes',
    api_retrieval: 'API',
    file_retrieval: 'Fichier'
  },
  deliveryMode: {
    title: 'Livraison',
    api_delivery: 'API',
    file_delivery: 'Fichier'
  },
  kindPrepaidAccount: {
    vat: 'TVA',
    overpayment: 'Trop payé',
    orderPayment: 'Bulk',
    topup: 'Virement entrant',
    fix: 'Correction',
    pull: 'API',
    orderPaymentCancellation: 'Annulation Bulk',
    topupCancellation: 'Annulation Topup',
    pullRefund: 'Remboursement',
    cardRefund: 'Remb. carte'
  },
  orderFormDialog: {
    createOrderBtn: 'Passer une commande',
    titleUpdate: 'Edition de la commande',
    titleCreate: 'Créer une commande',
    infoGeneral: 'Informations générales',
    giftCard: 'Enseignes',
    selectGiftCard: 'Selectionnez une enseigne',
    paymentRule: 'Règle de paiement',
    paymentDelay: {
      title: 'Délai de paiement',
      atReception: 'à reception',
      days: 'jours'
    },
    drawingCeiling: {
      title: 'Plafond de tirage',
      unlimited: 'illimité'
    },
    remainingDrawdownLimit: {
      title: 'Plafond de tirage restant',
      unlimited: 'illimité'
    },
    refCustomer: 'Référence de transaction client',
    opt: '(en option)',
    phrasing: {
      line1: 'Indiquez ici la référence de transaction que vous allez utiliser pour votre virement.',
      line2: 'Si non renseignée, veuillez utiliser la référence de commande.'
    },
    retrievalMode: 'Livraison des codes',
    retrievalModeOptions: {
      api: 'API',
      file: 'Fichier'
    },
    retrievalModeHelp: 'Choisissez API si vous voulez que ces codes soient mis en stock afin de les récupérer par API. Choisissez fichier, si vous voulez récupérer les codes via un fichier csv.',
    detailOrder: {
      title: 'Détailler ma commande ?',
      yes: 'Oui',
      no: 'Non'
    },
    formDetails: {
      alert: {
        title: 'Actuellement',
        title2: 'sur une commande de',
        compliant: 'Le détail de votre commande est égal au montant total de la commande en valeur faciale.',
        improper: 'Le détail n\'est pas égal au montant total de la commande en valeur faciale.'
      },
      amountCards: 'Nombre de carte(s)',
      amount: 'Montant (€)',
      amountCardsReserved: 'Carte(s) à réserver',
      tooltipCardsReserved: 'La quantité de carte en statut à réserver sera réservé pour approvisionner manuellement ou automatiquement les commandes fermées des acheteurs',
      requiredQuantity: 'Quantité requise',
      amountRequired: 'Montant requis',
    },
    summary: {
      title: 'Vous disposez actuellement de',
      title2: 'sur votre solde.',
      title3: 'Souhaitez vous l\'utiliser pour cette commande ?',
      balanceToUse: 'Solde à utiliser',
      btnMax: 'Max',
      amountDetails: 'Détails du montant à payer',
      faceValue: 'Valeur faciale',
      commissionTTCPercentage: 'Commission TTC en %',
      commissionTTCValue: 'Commission TTC en valeur',
      commissionHT: 'Commission HT',
      credit: 'Avoir',
      creditTips: 'Avoir correspondant à la TVA sur la commission de distribution.',
      amountAfterDiscount: 'Montant après remise',
      prepaidAccount: 'Solde disponible',
      defferedUsage: 'Usage différé',
      remainingAmount: 'Montant restant dû',
      haveCredited: 'Avoir crédité',
      pendingPayment: 'Avoir en attente de versement'
    },
    rules: {
      requiredGiftCard: 'Enseigne requise',
      requiredAmount: 'Le montant de la commande est requis',
      detailsNotEqual: 'Le total du détail n\'est pas égal au montant de la commande'
    },
    notification: {
      create: {
        title: 'Commande créée',
        message: 'La commande a été créée avec succès'
      },
      update: {
        title: 'Commande mise à jour',
        message: 'La commande a été mise à jour'
      },
      error: {
        title: 'Commande non créée',
        message: 'Une erreur est survenue'
      },
      delete: {
        title: 'Commande supprimée',
        message: 'La commande a été supprimée avec succès'
      }
    },
    btnOrder: 'Commander',
    btnUpdate: 'Modifier'
  },
  deliveries: {
    card: {
      title: 'Livraisons',
      btnSee: 'Détail / Téléchargement',
      totalOfCodes: 'Total des codes',
      totalOfCodesByAPI: 'Distribués par API',
      totalOfCodesByFile: 'Distribués par Fichier',
      file: 'Fichier',
      downloaded: 'Téléchargé',
      yes: 'Oui',
      partially: 'Partiellement',
      no: 'Non'
    },
    dialog: {
      title: 'Mes livraisons',
      files: {
        codeFileBlank: 'Aucun fichier généré',
        status: {
          title: 'Statut',
          generated: 'Prêt a être téléchargé',
          notGenerated: 'Non généré',
        },
        detail: {
          title: 'Détail de la livraison',
        },
        download: {
          firstDownload: 'Téléchargé la première fois le',
          lastDownload: 'Téléchargé la dernière fois le',
        },
        btn: {
          launchDownload: 'Télécharger le fichier',
          generatePDFBtn: 'Générer les PDF des cartes',
          fileGenerating: 'Génération du fichier...',
          generateAndDonwload: 'Générer et télécharger le fichier',
          confirm: {
            title: 'Êtes vous sur de vouloir générer un fichier de codes sans PDF ?',
            opt1: 'Annuler',
            opt2: 'Confirmer',
          }
        }
      }
    }
  },
  createBuyerDialog: {
    createTitle: 'Création d\'un acheteur',
    updateTitle: 'Mise à jour d\'un acheteur',
    nameInput: `Nom de l'acheteur`,
    codeInput: 'Préfixe acheteur',
    rcsInput: 'RCS',
    intraVatNumberInput: 'N° TVA Intracommunautaire',
    sirenInput: 'Siren',
    createNewAccount: 'Créer un compte utilisateur pour cet acheteur',
    accountFirstNameInput: 'Prénom',
    accountLastNameInput: 'Nom',
    accountEmailInput: 'Email',
    title2: 'Informations secondaires',
    logoInput: `Logo de l'acheteur`,
    locationNameInput: 'Raison sociale',
    locationLine1Input: 'Adresse',
    locationLine2Input: `Complément d'adresse`,
    locationZipCodeInput: 'Code postal',
    locationCityCodeInput: 'Ville',
    locationStateCodeInput: 'Pays',
    abortBtn: 'Annuler',
    confirmBtn: `Créer l'acheteur`,
    updateBtn: `Mettre à jour`,
    createAccountBtn: `Créer le compte`,
    rules: {
      name: `Le nom de l'acheteur est requis`,
      code: 'Le préfixe acheteur est requis',
      rcs: 'Le RCS est requis',
      intraVatNumber: 'Le N° TVA Intracommunautaire est requis',
      siren: 'Le siren est requis',
      locationName: 'La raison sociale est requise',
      locationLine1: `L'adresse est requise`,
      locationZipCode: 'Le code postal est requis',
      locationCity: `La ville est requise`,
      locationState: 'Le pays est requis',
      firstname: 'Le prénom est requis',
      lastname: 'Le nom de famille est requis',
      email: `L'email est requis`,
    },
    notifications: {
      success: {
        title: 'Acheteur créé',
        subtitle: `L'acheteur a été créé avec succès`
      },
      error: {
        title: 'Erreur',
        subtitle: `L'acheteur n'a pas pu être crée`
      },
      successUpdate: {
        title: 'Acheteur mis à jour',
        subtitle: `L'acheteur a été mis à jour avec succès`
      },
      successAccount: {
        title: 'Compte créé',
        subtitle: `Le compte pour l'acheteur a été créé`
      },
      errorAccount: {
        title: 'Erreur',
        subtitle: `Le compte n'a pas pu être créé`
      }
    }
  },
  topupBuyerDialog: {
    title: 'Ajout de solde pour',
    amountInput: 'Montant (€)',
    referenceInput: 'Référence du virement',
    sendAtInput: 'Effectué le',
    sendAtPlaceholder: 'Sélectionnez date et horaire',
    abortBtn: 'Annuler',
    confirmBtn: 'Confirmer',
    rules: {
      amount: 'Un montant est requis',
      reference: 'La référence du virement est requise',
      sendAt: 'La date du virement est requise'
    },
    notifications: {
      success: {
        title: `L’acheteur a été crédité`,
        subtitle: 'crédité le'
      },
      error: {
        title: `Une erreur s'est produite`,
        subtitle: `L'acheteur n'a pas pu être crédité`
      }
    }
  },
  customerDialog: {
    approvedAuto: 'Client approuvé automatiquement',
    approvedManual: 'Client approuvé manuellement',
    createTitle: 'Nouveau client',
    updateTitle: `Mise à jour d'un client`,
    firstnameInput: 'Prénom',
    lastnameInput: 'Nom',
    businessEmailInput: 'Email professionnel',
    checkEmailBtn: 'Vérifier l\'email',
    emailInput: 'Email de connexion',
    emailConfirmation: {
      confirmedByEmail: 'Confirmé via email le',
      notConfirmed: 'Email non confirmé',
      confirmedManually: 'Confirmé manuellement',
      validationInProgress: 'est en cours de validation'
    },
    emailConfirmationStatus: {
      title: 'Statut email',
      accepted: 'File d\'attente',
      rejected: 'Rejeté',
      delivered: 'Délivré',
      failed: 'Echoué',
      opened: 'Ouvert',
      clicked: 'Cliqué',
      unsubscribed: 'Désinscrit',
      complained: 'Complaint',
      stored: 'Stocké',
      on: 'le'
    },
    optinNewsletterInput: 'Le client souhaite recevoir des newsletters',
    codeCniegInput: 'Code CNIEG',
    situationInput: 'Situation',
    retired: 'Retraité',
    employee: 'Salarié',
    phoneInput: 'Téléphone',
    programInput: 'Programme',
    abortBtn: 'Annuler',
    confirmBtn: 'Créer le client',
    updateBtn: 'Mettre à jour',
    rules: {
      firstname: 'Le prénom est requis',
      lastname: 'Le nom est requis',
      email: 'L\'email est requis',
      programId: 'Le programme est requis',
    },
    notifications: {
      success: {
        title: 'Succès',
        subtitle: 'Le client à été créé'
      },
      update: {
        title: 'Succès',
        subtitle: 'Le client a été mis à jour'
      },
      error: {
        title: `Une erreur s'est produite`,
        subtitle: `Le client n'a pas pu être créé ou mis à jour`
      },
      checkEmailSuccess: {
        title: 'Succès',
        subtitle: 'L\'email a été vérifié avec le statut :'
      },
      checkEmailError: {
        title: `Une erreur s'est produite`,
        subtitle: `L'email n'a pas pu être vérifié`
      }
    }
  },
  manageDomainDialog: {
    title: 'Liste des domaines acceptés',
    selectLabelProgram: 'Domaines du programme :',
    selectPlaceholderProgram: 'Sélectionnez un programme',
    domainSearchInput: 'Rechercher un domaine',
    popconfirmDelete: {
      title: 'Êtes-vous sûr de vouloir supprimer ce domaine ?',
      cancelBtn: 'Annuler',
      confirmBtn: 'Confirmer'
    },
    popoverAdd: {
      title: 'Domaine à accepter',
      subtitle: 'Saisissez un domaine à ajouter dans la liste',
      abortBtn: 'Annuler',
      confirmBtn: 'Confirmer',
      addDomainBtn: 'Ajouter un domaine',
      rules: {
        domainRequired: 'Domaine requis',
        domainInvalid: 'Domaine invalide',
      }
    },
    notifications: {
      success: {
        title: 'Succès',
        message: 'Liste de domaine à étè mise à jour'
      },
      error: {
        title: 'Une erreur s\'est produite',
        message: 'Liste de domaine n\'a pas pu être modifier'
      }
    }
  },
  purchaseInfoBuyerDialog: {
    createTitleSeller: `Ajout d'un fournisseur pour`,
    updateTitleSeller: `Mise à jour du fournisseur pour`,
    createTitle: `Assignement d'un acheteur`,
    updateTitle: `Mise à jour d'un acheteur`,
    form: {
      titleInformations: 'Informations générales',
      inputSeller: 'Fournisseur',
      inputBuyer: 'Acheteur',
      inputPayVat: 'Paiement de la TVA',
      inputOffset: 'Mode de compensation',
      inputPartialModeOffset: 'Mode de paiement de la TVA',
      inputDifferedPartialOffset: 'Délai de versement de la TVA (en jour)',
      inputVatPayBack: 'Mode de versement de la TVA',
      inputPaymentMode: 'Mode de paiement',
      titleRate: 'Configuration du taux de commission',
      inputRate: 'Taux de commission (%)',
      titleDeferred: 'Caractéristiques du paiement différé',
      inputDeferredAmountLimit: 'Plafond du différé',
      inputDeferredAmountUsage: 'Différé utilisé',
      inputDeferredDuration: 'Délai de paiement',
      inputInvoiceAt: 'Facturation',
      inputApiEnable: 'API activée ?',
      apiEnableOptions: {
        yes: 'Oui',
        no: 'Non',
      },
      btnCreateSeller: `Ajouter le fournisseur`,
      btnUpdateSeller: `Mettre à jour le fournisseur`,
      btnCreate: `Ajouter l'acheteur`,
      btnUpdate: `Mettre à jour l'acheteur`,
      btnAbort: 'Annuler',
      notification: {
        success: {
          title: `Création des informations d'achat`,
          subtitle: `L'acheteur a été ajouté`
        },
        successSeller: {
          title: `Ajout d'un nouveau fournisseur`,
          subtitle: `Le fournisseur a été ajouté`
        },
        update: {
          title: 'Succès',
          subtitle: `Mise à jour des informations d'achat`
        },
        updateSeller: {
          title: `Succès`,
          subtitle: `Mise à jour des informations d'achat du fournisseur`
        },
        error: {
          title: 'Erreur',
          subtitle: `Une erreur s'est produite lors de l'ajout de l'acheteur`
        },
        errorSeller: {
          title: 'Erreur',
          subtitle: `Une erreur s'est produite lors de l'ajout du fournisseur`
        }
      }
    }
  },
  detailsDialog: {
    ref: 'Ref',
    trackingControl: 'Suivi de livraison',
    subtitle: 'Suivez en temps réél le statut de votre livraison',
    status: {
      undelivered: 'Non livrée',
      inDelivery: 'En livraison',
      complete: 'Livrée'
    },
    codeDeliveries: 'Livraisons des codes',
    deliveriesSubtitle: 'Liste des livraisons des codes relatifs à cette commande',
    deliveriesList: {
      delivery: 'Code livré: {sum} sur {total} | Codes livrés: {sum} sur {total} | Codes livrés: {sum} sur {total}',
      activationDate: 'Date d\'activation',
      inactiveCode: 'Code(s) inactif(s)',
      amountTotal: 'Montant total',
      deliveryDetails: 'Détail de la livraison',
      equalAmount: '{quantity} carte d\'un montant égal à {amount} | {quantity} cartes d\'un montant égal à {amount} | {quantity} cartes d\'un montant égal à {amount}',
      quantityReserved: ', dont {quantityReserved} carte réservée | , dont {quantityReserved} cartes réservées | , dont {quantityReserved} cartes réservées',
      empty: 'Non renseigné',
      downloadFile: 'Télécharger le fichier code en .CSV'
    },
    remainingDraw: 'Tirage restant',
    subtitleDetail: 'Détail de la commande',
    notSpecified: 'Non renseignée',
    downloadStandardInvoiceBtn: 'Télécharger la facture standard',
    downloadCommissionInvoiceBtn: 'Télécharger la facture de commission'
  },
  uploadCodes: {
    errors: {
      CODE_DUPLICATION: 'Code présent en doublon dans le fichier',
      CODE_ALREADY_EXISTS: 'Code déjà livré dans une commande antérieure',
      SKU_NOT_FOUND: 'Valeur faciale non commandée',
      CODE_NOT_ORDERED: 'Code livré en trop',
      UNHANDLED_PARSING_ERROR: 'Le fichier n\'est pas lisible',
      NEGATIVE_AMOUNT: 'Valeur faciale négative',
      MISSING_CODE: 'Code manquant',
      INVALID_EXPIRY_DATE: 'Date d\'expiration invalide'
    },
    found: 'erreur(s) trouvée(s)'
  },
  components: {
    changeLanguageTips: 'Changement de langue effectué',
    copySuccess: 'La valeur a été copiée',
    copyError: 'La valeur n\'a pas pu être copiée'
  },
  common: {
    noData: "Il n'y a pas de données à afficher",
  },
  delete: {
    title: 'Voulez-vous vraiment supprimer ceci ?',
    abort: 'Annuler',
    confirm: 'Confirmer',
    notification: {
      success: {
        title: 'Succès',
        subtitle: 'Supprimé avec succès'
      },
      error: {
        title: 'Erreur',
        subtitle: 'Une erreur est survenue'
      }
    }
  },
  roles: {
    seller: 'fournisseur',
    wholesaler: 'grossiste',
    buyer: 'acheteur',
    caretaker: 'concierge'
  }
}
