import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { getLanguage } from '@/utils/cookies'

// element-ui built-in lang
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementFrLocale from 'element-ui/lib/locale/lang/fr'

// User defined lang
import enLocale from './en'
import frLocale from './fr'

const defaultImpl = VueI18n.prototype.getChoiceIndex

VueI18n.prototype.getChoiceIndex = function(choice, choicesLength) {
  if (choice === 0) return 0

  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1

  if (!teen && endsWithOne) return 1
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) return 2

  return (choicesLength < 4) ? 2 : 3
}

Vue.use(VueI18n)

const numberFormats = {
  en: {
    decimal: {
      style: 'decimal',
      separator: ' ',
      delimiter: ',',
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    },
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol'
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    }
  },
  fr: {
    decimal: {
      style: 'decimal',
      separator: ' ',
      delimiter: ',',
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    },
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol'
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    }
  }
}

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  fr: {
    ...frLocale,
    ...elementFrLocale
  }
}

export const getLocale = () => {
  const cookieLanguage = getLanguage()
  if (cookieLanguage) {
    document.documentElement.lang = cookieLanguage
    return cookieLanguage
  }

  const language = navigator.language.toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      document.documentElement.lang = locale
      return locale
    }
  }

  return 'fr'
}

const i18n = new VueI18n({
  locale: getLocale(),
  messages,
  numberFormats: numberFormats
})

export default i18n
